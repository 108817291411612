<template>
  <div class="ft_copyright" v-html="copyRight"></div>
</template>

<script>

export default {
  props: {
    lang: {
      type: String
    }
  },
  computed: {
    copyRight () {
      return this.lang === 'English'
        ? '<P>Copyright © 2021-2023 VIRTAITECH.COM<br /><a href="https://beian.miit.gov.cn" target="_blank">JING ICP 19027973</a></p>'
        : '<P>© 2021-2023 virtaitech.com 版权所有<br /><a href="https://beian.miit.gov.cn" target="_blank">京ICP备19027973号</a><br /><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802034886" target="_blank">京公网安备11010802034886号</a></p>'
    }
  }
}
</script>

<style lang="less" scoped>
.ft_copyright{
  width: 100%;
  padding: 20px 0 108px 0;
  color: @grey;
  font-size: 22px;
  line-height: 34px;
  background: #F5F9FF;
  text-align: center;
}
</style>
