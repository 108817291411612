<template>
  <van-popup v-model="menuStatus" position="right" closeable :close-icon="close" :style="{ width: '67%', height: '100%' }"
    @close="closeMenu">
    <div class="nav">
      <ul class="nav-list">
          <!-- 一级 -->
        <li v-for="(nav, index) of navs" :key="nav.path" class="nav-item" :class="{ active: _judgeActive(nav) }">
          <div class="nav-wrap" @click="handleNavClick(nav, index)">
            <p class="blue_underline" :style="{ fontWeight: curIndex === index ? 'bolder' : 'inherit' }">{{ nav.title }}</p>
            <div class="arrow" :class="{ down: curIndex === index }" v-if="nav.children">
              <img v-show="curIndex === index" src="@/assets/images/arrow_down_blue.svg" />
              <img v-show="curIndex !== index" src="@/assets/images/arrow_down.svg" />
            </div>
          </div>
          <ul class="nav-child" v-if="nav.children" :class="{ close: curIndex !== index }">
            <template v-if="nav.meta.dynamicChildren">
              <!-- 二级 -->
              <li v-for="solution in solutionMenu" :key="solution.catalog">
                <ul>
                  <div class="nav-wrap level2" @click="handleClickLevel2(solution)">
                    <p>{{ solution.catalog }}</p>
                    <div class="arrow" :class="{ down: solution.expand }">
                      <img v-show="solution.expand" src="@/assets/images/arrow_down_blue.svg" />
                      <img v-show="!solution.expand" src="@/assets/images/arrow_down.svg" />
                    </div>
                  </div>
                  <!-- 三级 -->
                  <template v-if="solution.expand">
                    <li class="case-item" v-for="caseItem in solution.cases" :key="caseItem.id" @click="closeMenu">
                      <router-link :to="nav.path + '?id=' + caseItem.id">
                        {{ caseItem.title }}
                      </router-link>
                    </li>
                  </template>
                </ul>
              </li>
            </template>
            <template v-else>
              <!-- 二级 -->
              <li v-for="child in nav.children" :key="child.catalog">
                <ul>
                  <div class="nav-wrap level2" @click="handleClickLevel2(child)">
                    <p>
                      {{ child.catalog }}
                      <span v-if="child.showNewTag" style="color: #2688EA;">NEW</span>
                    </p>
                    <div class="arrow" :class="{ down: child.expand }">
                      <img v-show="child.expand" src="@/assets/images/arrow_down_blue.svg" />
                      <img v-show="!child.expand" src="@/assets/images/arrow_down.svg" />
                    </div>
                  </div>
                  <!-- 三级 -->
                  <template v-if="child.expand">
                    <li class="case-item" v-for="caseItem in child.cases" :key="caseItem.id" @click="closeMenu">
                      <router-link v-if="!caseItem.isExternalLink" :to="caseItem.url">
                        {{ caseItem.name }}
                      </router-link>
                      <a v-else :href="caseItem.url">
                        {{ caseItem.name }}
                      </a>
                    </li>
                  </template>
                </ul>
              </li>
            </template>
          </ul>
        </li>
      </ul>
      <div class="link-box" @click="closeMenu">
        <router-link class="english" to="/en">
          English
        </router-link>
        <a v-if="token" @click.prevent="handleSignout">退出</a>
      </div>
    </div>
  </van-popup>
</template>

<script>
import close from '~/assets/images/icon_close.png'
import { mapGetters, mapActions } from 'vuex'
export default {
  props: {
    showMenu: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      close,
      curIndex: -1,
      menuStatus: false
    }
  },
  watch: {
    showMenu () {
      this.menuStatus = this.showMenu
    }
  },
  computed: {
    // 此处 navs 的数据结构较 pc 端版本有差异，对 isExternalLink 的处理也不同，这里是简单的使用 a 标签
    ...mapGetters({
      token: 'token',
      navs: 'headerNavs'
    }),
    solutionMenu () {
      return this.$store.state.solutionList || []
    }

  },
  methods: {
    ...mapActions(['signout']),
    toPartnerPortal () {
      location.href = appEnv.config.partnerPortal
    },
    handleSignout () {
      this.signout()
      if (this.$route.path.includes('user')) {
        this.$router.replace('/')
      }
    },
    closeMenu () {
      this.$emit('closeMenu')
    },
    handleNavClick (nav, index) {
      if (!nav.children) {
        this.$router.push(nav.path)
        this.closeMenu()
      } else {
        // 收起解决方案菜单
        for (const item of this.solutionMenu) {
          item.expand = false
        }
        this.curIndex = this.curIndex === index ? -1 : index
      }
    },
    handleClickLevel2 (item) {
      item.expand = !item.expand
    },
    _judgeActive (nav) {
      if (nav.path === '/') return nav.path === this.$route.path
      return this.$route.path.includes(nav.path)
    }
  }
}
</script>

<style lang="less">
.nav {
  height: 100%;
  padding-top: 80px;
  position: relative;

  .nav-list {
    color: @black;
    height: calc(100% - 176px);
    overflow-y: auto;

    .nav-item {
      font-size: 30px;

      .nav-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 96px;
        line-height: 96px;
        padding: 0 32px 0 40px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);

        &.level2 {
          height: 76px;
          line-height: 76px;
          padding: 0 32px 0 0;
          border-bottom: none;
        }

        .blue_underline {
          position: relative;
          &::after {
            content: "";
            position: absolute;
            left: 50%;
            bottom: 0;
            width: 0;
            height: 3px;
            background: @blue;
            transform: translateX(-50%);
            transition: width .25s;
          }
        }

        .arrow {
          display: flex;
          width: 36px;
          height: 36px;
          // background: url("~@/assets/images/arrow_down.png") no-repeat;
          // background-size: 100%;
          transition: transform .5s;

          img {
            width: 100%;
          }

          &.down {
            transform: rotateX(180deg);
            color: #0073E6;
          }
        }
      }

      &.active {
        .nav-wrap {
          font-weight: bolder;

          p::after {
            width: 100%;
          }
        }
      }

      .nav-child {
        text-indent: 60px;
        overflow: hidden;
        transition: height .25s;
        background: @bg-grey;
        font-size: 28px;
        line-height: 44px;
        color: @text65;

        &.close {
          height: 0;
        }

        li {

          a {
            display: block;
            height: 76px;
            line-height: 76px;
          }

          .case-item {
            a {
              display: block;
              line-height: 44px;
              text-indent: 0;
              font-size: 28px;
              padding: 16px 40px 16px 80px;
              color: #7A849A;
              height: auto;
            }
          }
        }
      }
    }
  }

  .link-box {
    border-top: 1px solid rgba(0,0,0,0.1);
    position: absolute;
    left: 0;
    bottom: 64px;
    .flex(@justify: space-between);
    width: 100%;
    padding: 16px 40px 0 40px;
    font-size: 28px;
    height: 96px;
    line-height: 96px;
    color: @black;
  }
}
</style>
