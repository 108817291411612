import Vue from 'vue'
import VueStorage from 'vue-ls'
import App from './App.vue'
import store from './store'
import router from './router'
import FastClick from 'fastclick'
import i18n from './locales'
import '@vant/touch-emulator'
import '@/router/guard'
import '@/utils/filter.js'
import '@/plugins/vant.js'
import '@/plugins/antd.js'
import '@/assets/style/antd.less'
import '@/assets/style/antdpx.less'
import '@/assets/style/vant.less'
import '@/assets/fonts/iconfont.css'
import '@/assets/style/reset.less'
import '@/assets/style/tailwind.css'
// import VConsole from 'vconsole'

Vue.config.productionTip = false

Vue.use(VueStorage, {
  namespace: 'm_virtar_tech_', // key prefix
  name: 'ls', // name variable Vue.[ls] or this.[$ls],
  storage: 'local' // storage name session, local, memory
})

FastClick.attach(document.body)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line no-unused-vars
  // const vConsole = new VConsole()
}
