<template>
  <van-sticky @scroll="handleScroll">
    <header class="header" :class="{white: isWhite || isAlwaysWhite}">
      <router-link class="logo" :to="lang === 'English' ? '/en' : '/'">
        <img :src="currentLogo" alt="趋动科技">
      </router-link>
      <div class="header-right">
        <template v-if="lang === 'English'">
          <router-link class="to-chinese" to="/">
            简体中文
          </router-link>
        </template>
        <template v-else>
          <a v-if="$route.fullPath !== '/'" @click.prevent="toHome">
            首页
          </a>
          <div class="menu" @click="handleMenu">
            <i class="icon iconfont icon-menu"></i>
          </div>
        </template>
      </div>
      <c-menu :showMenu="showMenu" @closeMenu="_asyncClosePop"></c-menu>
    </header>
  </van-sticky>
</template>

<script>
import logo from '~/assets/images/logo.png'
import logoWhite from '~/assets/images/logo-white.png'
import { mapGetters, mapActions } from 'vuex'
import menu from '~/components/menu'
export default {
  components: {
    cMenu: menu
  },
  props: {
    isWhiteProp: {
      type: Boolean,
      default: false
    },
    lang: {
      type: String
    }
  },
  data () {
    return {
      isWhite: false,
      showMenu: false
    }
  },
  created () {
    this.$store.dispatch('fetchSolutionList')
  },
  watch: {
    lang: {
      handler (val) {
        console.log('val: ', val)
        this.$i18n.locale = val === 'English' ? 'en-GB' : 'zh-CN'
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['phone', 'token', 'userType']),
    isAlwaysWhite () {
      const path = this.$route.path
      const headerWhite = this.$route.meta?.headerWhite
      const keys = ['/development/index']
      return headerWhite || keys.includes(path) || this.isWhiteProp
    },
    currentLogo () {
      return this.isWhite || this.isAlwaysWhite ? logo : logoWhite
    }
  },
  methods: {
    ...mapActions(['signout']),
    handleScroll (e) {
      this.isWhite = e.isFixed
    },
    handleUser () {
      if (this.userType === 0) {
        this.$router.push({
          path: '/user/index'
        })
      } else {
        this.$toast.fail('请前往PC端进行订单管理')
      }
    },
    toAccount (path) {
      this.$router.push({
        path: `/account/${path}`
      })
    },
    toHome () {
      this.$router.push({
        path: '/'
      })
    },
    handleMenu () {
      this.showMenu = true
    },
    _asyncClosePop () {
      setTimeout(() => {
        this.showMenu = false
      }, 150)
    }
  }
}
</script>

<style lang="less" scoped>
  .header{
    position: relative;
    z-index: 9;
    .flex;
    justify-content: space-between;
    width: 100%;
    height: @header-height;
    padding: 0 30px;
    color: #fff;
    background: rgba(0, 13, 51, .3);
    .logo{
      width: 222px;
      height: 40px;
      img{
        width: 100%;
      }
    }
    .header-right{
      display: flex;
      a{
        display: block;
        margin-right: 16px;
        font-size: 26px;
        opacity: .7;
        touch-action: none;
        line-height: 30px;
        &.to-chinese{
          margin-right: 0;
        }
        &.icon-user{
          margin-right: 10px;
          font-size: 30px;
        }
      }
      .menu{
        margin-left: 16px;
        .icon{
          display: block;
          opacity: .7;
          transition: .3s;
          border-radius: 2px;
          color: #fff;
          font-size: 28px;
        }
      }
    }
    &.white{
      background: #fff;
      color: @blue;
      .header-right {
        .menu{
          .icon{
            color: @blue;
          }
        }
      }
    }
  }
</style>
