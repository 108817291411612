import Vue from 'vue'
import VueRouter from 'vue-router'
import { routes } from './config'

['push', 'replace'].map(key => {
  return {
    k: key,
    prop: VueRouter.prototype[key]
  }
}).forEach(item => {
  VueRouter.prototype[item.k] = function newCall (location, onResolve, onReject) {
    if (onResolve || onReject) return item.prop.call(this, location, onResolve, onReject)
    return item.prop.call(this, location).catch(err => err)
  }
})

if (!window.VueRouter) Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: (to, from) => {
    if (to.path !== from.path || to.path.includes('news/detail')) {
      return {
        y: 0
      }
    } else {
      return {}
    }
  },
  routes
})

export default router
