// import axios from 'axios'
// import { publicPath } from '@/utils/variable'

export default {
  fetchNews () {
    // return axios.get(`${publicPath}news.json`).then(res => {
    //   const { data: { news } } = res
    //   return news
    // })
  }
}
