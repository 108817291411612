import Vue from 'vue'
import HttpUtil from '@/utils/HttpUtil'
import store from '@/store/index'
import { baseURL, AppKey, exceptionMap } from '@/utils/variable'
import { Toast, Dialog } from 'vant'
import router from '@/router/index'

const err = (error) => {
  // 暂行办法-避免build打包时将错误弹窗打包入页面
  if (typeof error === 'object' && error.message !== 'Network Error') {
    Toast.fail('网络错误，请稍后重试')
  }
  return Promise.reject(error)
}

const base = new HttpUtil({
  baseURL,
  headers: {
    AppKey
  },
  timeout: 1000 * 30
})

base.instance.interceptors.request.use(config => {
  const token = Vue.ls.get('token')
  if (token) {
    config.headers.Authorization = `Bearer ${token}` // 让每个请求携带自定义 token 请根据实际情况自行修改
  }
  return config
}, () => {})

// response interceptor
base.instance.interceptors.response.use((response) => {
  const { code, data } = response.data
  if (response.config.url.includes('/Licenses') && typeof response.data === 'string') {
    return Promise.resolve(response.data)
  }
  if (code !== 200 || !data) {
    const msg = exceptionMap[`${code}`] || '内部错误'
    switch (code) {
      case -15:
        store.dispatch('signout')
        Dialog.confirm({
          title: '提示',
          message: msg,
          confirmButtonText: '立即注册'
        }).then(() => {
          router.push({
            path: '/account/signup'
          })
        }).catch(() => {
          window.location.reload()
        })
        break
      case -16:
      case -18:
      case -19:
      case -30:
      case -31:
        console.log('错误码----' + code)
        break
      default:
        Toast.fail(msg)
    }
    return Promise.reject(code)
  }
  return data
}, err)

export default base
