<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapMutations, mapActions } from 'vuex'
import { TOKEN, USER_INFO, USER_TYPE } from '@/utils/variable'

export default {
  data () {
    return {
      target: '/'
    }
  },
  methods: {
    ...mapActions(['createNavs']),
    ...mapMutations(['setToken', 'setUserInfo', 'setUserType'])
  },
  mounted () {
    const token = Vue.ls.get(TOKEN)
    const userInfo = Vue.ls.get(USER_INFO) || {}
    const userType = Vue.ls.get(USER_TYPE) || 0
    this.setToken(token)
    this.setUserInfo(userInfo)
    this.setUserType(userType)
    this.createNavs()
  }
}
</script>
<style lang="less">
  html {
    font-family: 'Microsoft Yahei', Arial, sans-serif;
    font-size: 16px;
    color: @black;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
  }
  body {
    width: 100% !important;
  }
  #app {
    height: 100%;
    font-family: 'Microsoft Yahei', Arial, sans-serif;
  }
</style>
