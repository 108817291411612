import { titleSuffix } from '@/utils/variable'

export const setDocumentTitle = function (title) {
  document.title = title ? `${title} - ${titleSuffix}` : titleSuffix
}

export const getBaseUrl = function () {
  let apiUrl = ''
  if (process.env.NODE_ENV === 'production') {
    apiUrl = appEnv.config.apiBaseAddress
  } else {
    apiUrl = '/api'
  }
  return apiUrl
}
