import axios from 'axios'
import { cmsUrl as cmsApiUrl } from '../store/model/cmsApi'

export const cmsApi = {
  getVideos: () => {
    const data = requestFn('/Portal/api/graphql', `
    query ProductVideos {
      productVideo(status: PUBLISHED, orderBy: {createdUtc: DESC}) {
        contentItemId
        createdUtc
        description
        modifiedUtc
        publishedUtc
        displayText
        title
        video {
          urls
        }
        thumbnail {
          urls
        }
      }
    }
  `)
    return data
  },
  getIndexPageInfomation: () => {
    return requestFn('/Portal/api/graphql', `
    {
      partnerImage {
        images {
          urls
        }
      }
      industrySolutions {
        list {
          contentItems {
            ... on IndustrySolutionsItem {
              name
              introduction
              backgroundImage {
                urls
              }
              fileDescription {
                urls
              }
            }
          }
        }
      }
      orionXInformation {
        introduction
        detail
        productManual {
          urls
        }
        list {
          contentItems {
            ... on OrionXFeature {
              title
              content
              icon {
                urls
              }
            }
          }
        }
      }
      customerCase {
        list {
          contentItems {
            ... on CustomerCaseItem {
              contentItemId
              name
              introduction
              logo {
                urls
              }
              coverImage {
                urls
              }
              content {
                html
              }
            }
          }
        }
      }
      newsCount {
        total
      }
      news(status: PUBLISHED, skip: 0, first: 5, orderBy: { publishedUtc: DESC }) {
        contentItemId
        title
        publishedUtc
        introduction
        coverImage {
          urls
        }
        newsContent {
          html
        }
      }
      articleCount {
        total
      }
      article(status: PUBLISHED, skip: 0, first: 10, orderBy: { publishedUtc: DESC }) {
        contentItemId
        title
        publishedUtc
        introduction
        coverImage {
          urls
        }
        articleContent {
          html
        }
      }
    }
    `)
  },
  getDevelopDocList: () => {
    const data = requestFn('/Portal/api/graphql', `
    {
      documentLibrary {
        list {
          contentItems {
            ... on Document {
              documentTitle
              contentItemId
              category
            }
          }
        }
      }
    }
  `)
    return data
  },
  getDevelopDocDetail: (docId) => {
    const data = requestFn('/Portal/api/graphql', `
    {
      document(
        status: PUBLISHED
        where: {contentItemId: "${docId}"}
      ) {
        documentTitle
        documentAuthor
        category
        publishedUtc
        content {
          markdown
        }
      }
    }
    `)
    return data
  },
  getAboutCompany: () => {
    const data = requestFn('/Portal/api/graphql', `
    {
      companyInfo(status: PUBLISHED) {
        introduction
      }
      managementTeam {
        list {
          contentItems {
            ... on ManagementTeamItem {
              name
              position
              avatar {
                urls
              }
              titles {
                termContentItems {
                  displayText
                }
              }
              description
            }
          }
        }
      }
    }
  `)
    return data
  },
  getNewsList: (currentIndex, pageSize) => {
    const skip = (currentIndex - 1) * pageSize
    const data = requestFn('/Portal/api/graphql', `
    {
      newsCount {
        total
      }
      news(status: PUBLISHED, skip: ${skip}, first: ${pageSize}, orderBy: {publishedUtc: DESC}) {
        contentItemId
        title
        publishedUtc
        introduction
        coverImage {
          urls
        }
        newsContent {
          html
        }
      }
    }
  `)
    return data
  },
  getNewsDetail: (id) => {
    const data = requestFn('/Portal/api/graphql', `
    {
      newsPreviousItem(
        parameters: "{ContentItemId: '${id}'}"
      ) {
        title
        contentItemId
        publishedUtc
      }
      newsNextItem(
        parameters: "{ContentItemId: '${id}'}"
      ) {
        title
        contentItemId
        publishedUtc
      }
      news(where: {contentItemId: "${id}"}) {
        contentItemId
        title
        publishedUtc
        introduction
        coverImage {
          urls
        }
        newsContent {
          html
        }
      }
    }
  `)
    return data
  },
  getTechPagesList: (currentIndex, pageSize) => {
    console.log(currentIndex)
    const skip = (currentIndex - 1) * pageSize
    const data = requestFn('/Portal/api/graphql', `
    {
      articleCount {
        total
      }
      article(status: PUBLISHED, skip: ${skip}, first: ${pageSize}, orderBy: {publishedUtc: DESC}) {
        contentItemId
        title
        publishedUtc
        introduction
        coverImage {
          urls
        }
        articleContent {
          html
        }
      }
    }
  `)
    return data
  },
  getTechPagesDetail: (id) => {
    const data = requestFn('/Portal/api/graphql', `
    {
      articlePreviousItem(
        parameters: "{ContentItemId: '${id}'}"
      ) {
        title
        contentItemId
        publishedUtc
      }
      articleNextItem(
        parameters: "{ContentItemId: '${id}'}"
      ) {
        title
        contentItemId
        publishedUtc
      }
      article(where: {contentItemId: "${id}"}) {
        contentItemId
        title
        publishedUtc
        introduction
        coverImage {
          urls
        }
        articleContent {
          html
        }
      }
    }
  `)
    return data
  },
  getContactList: () => {
    const data = requestFn('/Portal/api/graphql', `
    query {
      webInfo(first: 1, status: PUBLISHED) {
        bDEmail
        companyName
        hREmail
        phone
        wechatPublicAccount {
          urls
        }
        bDWechat {
          urls
        }
      }
      companyContact {
        list {
          contentItems {
            ... on DepartmentContact {
              address
              name
              phone
              isHeadOffice
            }
          }
        }
      }
    }
  `)
    return data
  },
  getJobInfo: () => {
    const data = requestFn('/Portal/api/graphql', `
    query  {
      employeeWelfare {
        description
        welfareImage {
          urls
        }
      }
      hiring {
        list {
          contentItems {
            ... on HiringItem {
              name
              location
              jobType
              headCount
              contactEmail
              contactPhone
              publishedDate
              workContent
              skills
            }
          }
        }
      }
    }
  `)
    return data
  },
  getEnglishPage: () => {
    const data = requestFn('/Portal/api/graphql', `
    {
      englishPage(status: PUBLISHED) {
        title
        introduce
        companyDescription
        address
        email
        phone
        map {
          urls
        }
        orionXDetail
        list {
          contentItems {
            ... on OrionXFeature {
              title
              content
              icon {
                urls
              }
            }
          }
        }
      }
    }
  `)
    return data
  },
  getCaseDetail: (id) => {
    const data = requestFn('/Portal/api/graphql', `
    {
      customerCaseItem(where: {contentItemId: "${id}"}) {
        contentItemId
        name
        introduction
        logo {
          urls
        }
        coverImage {
          urls
        }
        content {
          html
        }
      }
    }
  `)
    return data
  },
  getSolutionList: () => {
    const data = requestFn('/Portal/api/graphql', `
    query {
      industrySolutionsV2 {
        list {
          contentItems {
            ... on IndustrySolutionsV2Class {
              name
              list {
                contentItems {
                  ... on IndustrySolutionsV2Item {
                    solutionTitle
                    contentItemId
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
    return data
  },
  getSolutionDetail: (id) => {
    const data = requestFn('/Portal/api/graphql', `
    query {
        industrySolutionsV2Item(where: {contentItemId: "${id}"}) {
          solutionTitle
          logoLink {
            url
          }
          logo {
            urls
          }
          demoVideos {
            urls
          }
          content {
            html
          }
          backgroundImage {
            urls
          }
        }
      }

  `)
    return data
  }
}

const requestFn = (apiPath, graphQl) => {
  return axios.post(cmsApiUrl + apiPath, {
    query: graphQl
  }).then(res => {
    return res.data
  }).catch(err => {
    console.error('error: ', err.message)
    return Promise.reject(err)
  })
}
