import index from '../views/index.vue'
import indexLayout from '@/layout/indexLayout'
import keepAliveLayout from '@/layout/keepAliveLayout'
import NotFoundComponent from '../views/404'

export const routes = [
  {
    path: '/',
    component: indexLayout,
    children: [
      {
        path: '/',
        component: index,
        meta: {
          title: '首页',
          showOnNav: true,
          share: {
            title: '趋动科技',
            description: '欢迎访问趋动科技官方网站！'
          }
        }
      },
      {
        path: '/product',
        redirect: '/product/index',
        component: () => import(/* webpackChunkName: "product" */ '../views/product/index.vue'),
        children: [
          {
            path: 'index',
            component: () => import(/* webpackChunkName: "product" */ '../views/product/index/index.vue'),
            meta: {
              title: 'OrionX',
              showOnNav: true,
              share: {
                title: '产品试用及购买',
                description: '还等什么？赶快来试用、购买我们的产品吧！'
              }
            }
          },
          {
            path: 'https://www.virtaicloud.com/',
            component: () => import(/* webpackChunkName: "product" */ '../views/product/index.vue'),
            meta: {
              title: '趋动云',
              showOnNav: true,
              isExternalLink: true,
              showNewTag: true
            }
          },
          {
            path: 'gemini',
            component: () => import(/* webpackChunkName: "product" */ '../views/product/gemini/index.vue')
          }
        ]
      },
      {
        path: '/solution',
        component: () => import('../views/solution/detail.vue'),
        meta: {
          title: '解决方案',
          showOnNav: true,
          dynamicChildren: true,
          headerWhite: true
        }
      },
      {
        path: '/development',
        redirect: '/development/index'
      },
      {
        path: '/development/index',
        component: () => import(/* webpackChunkName: "doc" */ '../views/development/doc.vue'),
        meta: {
          title: '技术文档',
          showOnNav: true,
          share: {
            title: '趋动科技技术文档'
          }
        }
      },
      {
        path: '/company',
        component: keepAliveLayout,
        redirect: '/company/about',
        meta: {
          title: '公司',
          showOnNav: true
        },
        children: [
          {
            path: 'about',
            component: () => import(/* webpackChunkName: "about" */ '../views/company/about.vue'),
            meta: {
              title: '关于公司',
              showOnNav: true,
              share: {
                title: '趋动科技公司介绍',
                description: '北京趋动科技有限公司成立于2019年4月，由多位具有世界500强中国研究院负责人背景并且具有共同远大理想的高科技人才创立，专注于为全球用户提供国际领先的数据中心级AI加速器虚拟化和资源池化软件及解决方案。'
              }
            }
          },
          {
            path: 'news/list',
            component: () => import(/* webpackChunkName: "newsList" */ '../views/company/news/list.vue'),
            meta: {
              title: '新闻资讯',
              showOnNav: true,
              share: {
                title: '趋动科技新闻资讯',
                description: '查看趋动科技新闻资讯列表'
              }
            }
          },
          {
            path: 'techPages/list',
            component: () => import(/* webpackChunkName: "newsList" */ '../views/company/techPages/list.vue'),
            meta: {
              title: '技术文章',
              showOnNav: false,
              share: {
                title: '趋动科技新闻资讯'
              }
            }
          },
          {
            path: 'news/detail',
            component: () => import(/* webpackChunkName: "newsDetail" */ '../views/company/news/detail.vue'),
            meta: {
              title: '新闻详情',
              share: {
                title: '趋动科技新闻详情'
              }
            }
          },
          {
            path: 'techPages/detail',
            component: () => import(/* webpackChunkName: "newsDetail" */ '../views/company/techPages/detail.vue'),
            meta: {
              title: '技术文章',
              share: {
                title: '趋动科技技术文章'
              }
            }
          }
        ]
      },
      {
        path: '/contact',
        component: { render: h => h('router-view') },
        redirect: '/contact/index',
        meta: {
          title: '联系',
          showOnNav: true
        },
        children: [
          {
            path: 'index',
            component: () => import(/* webpackChunkName: "contact" */ '../views/contact/index.vue'),
            meta: {
              title: '联系方式',
              showOnNav: true,
              share: {
                title: '趋动科技联系方式',
                description: '查看趋动科技联系方式'
              }
            }
          },
          {
            path: '/attention',
            meta: {
              title: '商务意向',
              showOnNav: true,
              isExternalLink: true
            },
            component: () => import('../views/attention/form.vue')
          },
          {
            path: '/attention?isTry=true',
            meta: {
              title: '申请试用',
              showOnNav: true,
              isExternalLink: true
            },
            component: () => import('../views/attention/form.vue')
          },
          {
            path: 'https://qudong.m.zhiye.com/JobAd/List?jc=1',
            component: () => import(/* webpackChunkName: "job" */ '../views/contact/job.vue'),
            meta: {
              title: '诚聘英才',
              showOnNav: true,
              share: {
                title: '趋动科技招聘信息',
                description: '趋动科技虚位以待，期待您的加入！'
              },
              isExternalLink: true
            }
          }
        ]
      },
      {
        path: '/product',
        redirect: '/product/index'
      },
      {
        path: '/user',
        component: { render: h => h('router-view') },
        redirect: '/user/index',
        children: [
          {
            path: 'index',
            component: () => import(/* webpackChunkName: "user" */ '../views/user/index.vue'),
            meta: {
              title: '个人中心',
              share: {
                title: '趋动科技个人中心',
                description: '您可以通过个人中心查看您的个人资料、订单等详细信息'
              }
            }
          },
          {
            path: 'gpulist',
            component: () => import(/* webpackChunkName: "GPUList" */ '../views/user/list.vue'),
            meta: {
              title: '个人中心',
              share: {
                title: '趋动科技个人中心',
                description: '您可以通过个人中心查看您的个人资料、订单等详细信息'
              }
            }
          }
        ]
      },
      {
        path: '/attention',
        meta: {
          title: '商务意向',
          showOnNav: false
        },
        component: () => import('../views/attention/form.vue')
      },
      {
        path: '/case/detail',
        meta: {
          title: '客户案例',
          showOnNav: false
        },
        component: () => import('../views/case/customer-case.vue')
      },
      {
        path: '/en',
        redirect: '/en/index'
      },
      {
        path: '/en/index',
        meta: {
          share: {
            title: 'VirtAI Tech',
            description: 'Started in April 2019, Beijing VirtAI Technology Co., Ltd. was founded by a number of high-tech talents who were former heads of the China Research Institutes of the Fortune 500 and had a common vision, focusing on providing global leading data-center class AI accelerator virtualization and resource management software and solutions for customers cross the world.'
          }
        },
        component: () => import(/* webpackChunkName: "english" */ '../views/english/index.vue')
      },
      {
        path: '/en/case/detail',
        meta: {
          share: {
            title: 'VirtAI Tech'
          }
        },
        component: () => import(/* webpackChunkName: "english.case.detail" */ '../views/english/case/detail.vue')
      },
      {
        path: '/en/company/news/detail',
        meta: {
          share: {
            title: 'VirtAI Tech'
          }
        },
        component: () => import(/* webpackChunkName: "english.news.detail" */ '../views/english/news/detail.vue')
      },
      {
        path: '/en/attention',
        meta: {
          share: {
            title: 'VirtAI Tech'
          }
        },
        component: () => import(/* webpackChunkName: "english.attention" */ '../views/english/attention/index.vue')
      }
    ]
  },
  {
    path: '/account',
    component: () => import(/* webpackChunkName: "accountLayout" */ '../layout/accountLayout.vue'),
    children: [
      {
        path: 'signup',
        component: () => import(/* webpackChunkName: "signup" */ '../views/account/signup.vue'),
        meta: {
          title: '注册',
          share: {
            title: '趋动科技用户注册',
            description: '欢迎成为趋动科技注册用户'
          }
        }
      },
      {
        path: 'signin',
        component: () => import(/* webpackChunkName: "signin" */ '../views/account/signin.vue'),
        meta: {
          title: '登录',
          share: {
            title: '趋动科技用户登录',
            description: '欢迎登录趋动科技官方网站'
          }
        }
      }
    ]
  },
  {
    path: '/403',
    component: () => import(/* webpackChunkName: "403" */ '../views/403.vue')
  },
  {
    path: '/500',
    component: () => import(/* webpackChunkName: "403" */ '../views/500.vue')
  },
  { path: '*', component: NotFoundComponent }
]
