export default {
  header: {
    title: 'Home',
    lang: 'english'
  },
  coopCase: {
    wholeCase: 'complete stories'
  },
  news: {
    component: {
      details: 'view details',
      more: 'more info'
    },
    detail: {
      title: 'Information details',
      prev: 'prev:',
      next: 'next:'
    }
  },
  caseDetail: {
    introduction: 'Case Introduction'
  },
  layout: {
    default: {
      productInquiry: 'Contact Sales'
    }
  },
  attention: {
    title: 'Contact Sales',
    secTitle: 'VirtAI sales team provides professional presales consulting services and recommends services and solutions.',
    form: {
      attention: {
        label: '业务意向',
        radio1: '产品',
        radio2: '行业解决方案'
      },
      appellation: {
        label: 'First Name',
        placeholder: ''
      },
      companyName: {
        label: 'Last Name',
        placeholder: ''
      },
      position: {
        label: 'Company Name',
        placeholder: ''
      },
      department: {
        label: '所属部门',
        placeholder: '请输入您所在部门'
      },
      contact: {
        label: 'Telephone',
        placeholder1: 'Please provide your phone number.',
        placeholder2: 'Please provide your Email address.'
      },
      demo: {
        label: 'demo演示环境',
        radio1: '申请',
        radio2: '不申请'
      },
      funcDescription: {
        label: '功能描述',
        placeholder: '请描述您希望了解哪些功能'
      },
      target: {
        label: '演示目标',
        placeholder: '请输入演示目标'
      },
      projectRequire: {
        label: '项目需求',
        placeholder: '如有具体项目需求，请填写',
        limit: '已输入'
      },
      needDoc: {
        label: '解决方案资料',
        radio1: '申请',
        radio2: '不申请'
      },
      docList: {
        label: '所需资料',
        options: {
          label1: '解决方案',
          label2: '单页',
          label3: '白皮书',
          label4: '其他'
        }
      },
      receiveEmail: {
        label: '接收邮箱',
        placeholder: '请输入用于接收资料的电子邮箱'
      },
      message: {
        label: 'Description',
        placeholder: 'Please provide more details about your requirements.',
        limit: 'Entered'
      },
      rule: {
        appellation: 'First Name is required',
        companyName: 'Last Name is required',
        contact: {
          error1: 'Please fill in at least one of the phone number and email address of the contact method',
          error2: 'Please enter the correct phone number',
          error3: 'Please enter the correct Email address',
          error4: 'Phone number is required'
        },
        funcDescription: '功能描述为必填项',
        target: '演示目标为必填项',
        docList: '所需资料为必填项',
        receiveEmail: {
          error1: '请输入正确的邮箱',
          error2: '接收邮箱为必填项'
        },
        message: 'Description is required'
      }
    },
    button: {
      submit: 'Submit',
      tip1: '提交意向后，工作人员会尽快联系您',
      tip2: 'Hotline: +82-010-6256-0919'
    },
    modal: {
      success: 'Thank you! Your information has been successfully submitted. We will contact you as soon as possible.',
      warn: '业务意向错误',
      error: 'Please try again later'
    }
  }
}
