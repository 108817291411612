import base from './base'
import cmsApi from './cmsApi'
export default {
  offersPost (params) {
    return base.post('/Offers', params)
  },
  offersGet () {
    return base.get('/Offers')
  },
  offersPendingGet () {
    return base.get('/Offers/pending')
  },
  offersPatch (userOfferId, params) {
    return base.patch(`/Offers/${userOfferId}`, params)
  },
  invitationCodePost (params) {
    return base.post('/InvitationCode', params)
  },
  invitationCodeGet () {
    return base.get('/InvitationCode')
  },
  invitationCodePatch (inviCodeId) {
    return base.patch(`/InvitationCode/${inviCodeId}/disable`)
  },
  purchaseintentions (params) {
    return base.post('/purchaseintentions', params)
  },
  submitSolution (params) {
    return base.post('/industry_solution', params)
  },
  graphqlApi (query) {
    const a = cmsApi.get('/Portal/api/graphql', { query })
    return a
  }
}
