import { getBaseUrl } from '@/utils/domUtil'

export const baseURL = getBaseUrl()

export const AppKey = appEnv.config.appKey

export const codeDuration = 60

export const exceptionMap = {
  '-1': '您输入的信息有误',
  '-2': '未授予调用后端API权限',
  '-3': 'Offer不存在',
  '-4': '您输入的验证码不正确',
  '-5': '发送验证码失败，请确保您输入的手机号码真实有效',
  '-6': '两次发送验证码间隔过短，请稍后再试',
  '-8': '发送验证码过于频繁，请10分钟后再试',
  '-11': '用户未登录',
  '-14': '您的手机号码已经注册',
  '-15': '用户不存在',
  '-16': '您的试用机会已用完，推荐进行购买！',
  '-17': '您可用的GPU数量已经超过上限',
  '-18': '您提交的购买申请正在处理中，请耐心等待。',
  '-19': '邮箱验证失败。',
  '-20': '邮件发送过于频繁，请10分钟后再试。',
  '-30': '邀请码无效，请联系销售人员进行确认。',
  '-31': '此邀请码与您的手机号不匹配，请联系销售人员进行确认。',
  500: '内部错误'
}

export const TOKEN = 'token'
export const USER_INFO = 'userInfo'
export const USER_TYPE = 'userType'

export const titleSuffix = '趋动科技'

export const GPU_CONFIG = {
  gpuCountLimit: 30,
  vgpuCountLimit: 30,
  exp: 1,
  reply: 5
}

export const publicPath = process.env.BASE_URL

// 部署文档url
export const deployDocUrl = 'https://github.com/virtaitech/orion-docs/blob/master/Orion-User-Guide.md#%E5%AE%89%E8%A3%85%E9%83%A8%E7%BD%B2'

// 技术文档url
export const techDocUrl = 'https://github.com/virtaitech/orion-docs/blob/master/Orion-User-Guide.md'

// 下载产品url
export const downloadProUrl = 'https://portalprodstorage.blob.core.chinacloudapi.cn/download-resources/orion-2.2.tar.gz'

export const excelConfig = {
  sheetName: 'GPUS'
}
export const adminStatusMap = {
  1: '审核通过',
  3: '审核未通过'
}
export const statusMap = {
  0: '待激活',
  1: '已激活',
  2: '已过期',
  3: '审核未通过'
}
export const statusCodeMap = {
  '-1': '已禁用',
  0: '未使用',
  1: '已使用'
}
// 0代表待激活，1代表已激活，2代表已过期，3代表取消
export const typeMap = {
  1: '免费试用',
  2: '一年期购买',
  3: '邀请码激活'
}

export const userTypeMap = {
  0: 'user',
  1: 'admin',
  2: 'preSale'
}

export const navs = [
  {
    name: '首页',
    path: '/'
  },
  {
    name: '技术文档',
    path: '/development/doc'
  },
  {
    name: '公司',
    path: '/company',
    children: [
      {
        name: '关于公司',
        path: '/company/about'
      },
      {
        name: '新闻资讯',
        path: '/company/news/list'
      },
      {
        name: '技术文章',
        path: '/company/techPages/list'
      }
    ]
  },
  {
    name: '联系',
    path: '/contact',
    children: [
      {
        name: '联系方式',
        path: '/contact/index'
      },
      {
        name: '诚聘英才',
        path: '/contact/job'
      }
    ]
  },
  {
    name: '产品',
    path: '/product',
    children: [
      {
        name: '试用',
        path: '/product?pos=trial'
      },
      {
        name: '购买',
        path: '/product?pos=purchase'
      },
      {
        name: '下载',
        path: '/product?pos=download'
      }
    ]
  }
]
