import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import content from './modules/content'
import nav from './modules/nav'
import getters from './getters'
import { cmsApi } from '../utils/cms'
import _ from 'lodash-es'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    solutionList: []
  },
  mutations: {
    updateSolutionList (state, payload) {
      state.solutionList = payload
    }
  },
  actions: {
    fetchSolutionList ({ commit }) {
      cmsApi.getSolutionList().then(resp => {
        const list = _.get(resp, 'data.industrySolutionsV2[0].list.contentItems', []).map(item => ({
          catalog: item.name,
          cases: _.get(item, 'list.contentItems').map((c) => ({
            title: c.solutionTitle,
            id: c.contentItemId
          })),
          expand: false
        }))
        commit('updateSolutionList', list)
      })
    }
  },
  modules: {
    user,
    content,
    nav
  },
  getters
})
