<template>
  <div class="exception">
    <c-header :isWhiteProp="true" />
    <div class="content">
      <div class="img-box">
        <img :src="config[type].img">
      </div>
      <div class="text-box">
        <h1>{{ config[type].title }}</h1>
        <div class="desc">{{ config[type].desc }}</div>
      </div>
      <a
        class="back-btn"
        type="info"
        @click.prevent="handleToHome"
      >返回首页
      </a>
    </div>
  </div>
</template>

<script>
import types from './type'
import header from '~/components/header'
export default {
  name: 'Exception',
  components: {
    cHeader: header
  },
  props: {
    type: {
      type: String,
      default: '404'
    }
  },
  data () {
    return {
      config: types
    }
  },
  methods: {
    handleToHome () {
      this.$router.replace({ path: '/' })
    }
  }
}
</script>
<style lang="less">
.exception {
  .flex(@direction: column, @align: stretch);
  position: relative;
  width: 100%;
  height: 100%;
  .content {
    .flex(@direction: column);
    flex: 1;
    width: 100%;
    height: 100%;
    text-align: center;
    img {
      display: block;
    }
    h1 {
      padding: 17px 0;
      font-size: 36px;
      color: @blue;
    }
    .img-box{
      width: 288px;
      height:392px;
    }
    .text-box{
      max-width: 75%;
    }
    .desc {
      color: #A0A0A0;
      font-size: 32px;
      line-height: 40px;
      text-transform: uppercase;
    }
    .back-btn {
      .radius_button(280px, 78px);
      text-align: center;
      margin: 110px auto 0;
      color: #fff;
      box-shadow:0 2px 15px 0 rgba(0,115,230,0.3);
    }
  }
}
</style>
