export default {
  arrowBorder: 'M0 0v1024h1024V0H0z m998.4 998.4H25.6V25.6h972.8v972.8z',
  arrowLeft: 'M616.96 716.8c-5.12 0-10.24-2.56-12.8-5.12l-212.48-181.76c-5.12-5.12-7.68-10.24-7.68-17.92 0-7.68 2.56-12.8 7.68-17.92l212.48-181.76c2.56-5.12 10.24-5.12 15.36-5.12 5.12 0 10.24 2.56 15.36 7.68 7.68 10.24 5.12 25.6-2.56 33.28L440.32 512l192 163.84c10.24 7.68 10.24 23.04 2.56 33.28-2.56 5.12-10.24 7.68-17.92 7.68z',
  arrowRight: 'M407.04 716.8c5.12 0 10.24-2.56 12.8-5.12l212.48-181.76c5.12-5.12 7.68-10.24 7.68-17.92 0-7.68-2.56-12.8-7.68-17.92l-212.48-181.76c-2.56-5.12-10.24-5.12-15.36-5.12-5.12 0-10.24 2.56-15.36 7.68-7.68 10.24-5.12 25.6 2.56 33.28l189.44 163.84-192 163.84c-10.24 7.68-10.24 23.04-2.56 33.28 5.12 5.12 12.8 7.68 20.48 7.68z',
  code: 'M19.068,15.650 C18.521,16.649 17.760,17.621 16.808,18.539 C15.779,19.531 14.600,20.385 13.400,21.008 C12.150,21.657 10.976,22.000 10.005,22.000 C9.104,22.000 8.017,21.701 6.863,21.135 C5.762,20.596 4.654,19.844 3.659,18.961 C2.600,18.021 1.711,16.965 1.087,15.908 C0.365,14.683 -0.000,13.477 -0.000,12.323 L-0.000,3.962 C-0.000,3.409 0.463,2.954 1.055,2.925 L1.075,2.925 L1.609,2.914 C1.701,2.912 3.615,2.855 5.585,2.113 C7.483,1.396 8.775,0.579 9.000,0.432 L9.002,0.431 L9.362,0.192 C9.548,0.066 9.770,-0.000 10.005,-0.000 C10.254,-0.000 10.480,0.072 10.661,0.208 L10.968,0.422 C11.058,0.482 12.399,1.358 14.416,2.123 C16.385,2.866 18.299,2.922 18.391,2.924 L18.398,2.925 L18.919,2.935 C19.516,2.939 20.000,3.403 20.000,3.973 L20.000,12.333 C20.003,13.399 19.689,14.516 19.068,15.650 ZM18.616,4.261 L18.370,4.256 C18.178,4.251 16.081,4.182 13.910,3.363 C12.742,2.920 11.793,2.444 11.203,2.122 C10.933,1.975 10.382,1.661 10.148,1.493 L10.147,1.494 L9.996,1.389 L9.840,1.493 L9.839,1.492 C9.614,1.651 9.122,1.936 8.801,2.112 C8.212,2.433 7.263,2.910 6.090,3.352 C3.918,4.171 1.822,4.241 1.630,4.245 L1.630,4.246 L1.384,4.251 L1.384,12.323 C1.384,14.107 2.555,16.170 4.597,17.982 C6.408,19.588 8.581,20.668 10.005,20.668 C10.737,20.668 11.709,20.373 12.743,19.836 C13.810,19.282 14.906,18.487 15.828,17.599 C17.604,15.886 18.620,13.967 18.616,12.335 L18.616,4.261 ZM9.280,14.094 C9.150,14.219 8.974,14.288 8.791,14.288 C8.613,14.288 8.441,14.222 8.312,14.103 L5.252,11.278 C4.976,11.024 4.967,10.602 5.232,10.337 C5.496,10.072 5.935,10.063 6.211,10.317 L8.783,12.691 L13.780,7.905 C14.051,7.646 14.489,7.647 14.759,7.907 C15.029,8.168 15.028,8.590 14.757,8.849 L9.280,14.094 Z',
  phone: 'M14.130,24.000 L1.871,24.000 C0.840,24.000 -0.000,23.171 -0.000,22.153 L-0.000,1.846 C-0.000,0.829 0.840,-0.000 1.870,-0.000 L14.130,-0.000 C15.159,-0.000 16.000,0.828 16.000,1.846 L16.000,22.153 C16.000,23.171 15.161,24.000 14.130,24.000 ZM1.247,22.153 C1.248,22.492 1.526,22.767 1.870,22.768 L14.128,22.768 C14.472,22.768 14.752,22.493 14.752,22.153 L14.752,18.396 L1.247,18.396 L1.247,22.153 ZM14.753,1.846 C14.753,1.507 14.473,1.231 14.130,1.231 L1.870,1.231 C1.527,1.231 1.247,1.507 1.247,1.846 L1.247,17.060 L14.753,17.060 L14.753,1.846 ZM8.000,21.695 C7.342,21.660 6.827,21.122 6.827,20.472 C6.827,19.822 7.342,19.285 8.000,19.249 C8.658,19.285 9.173,19.822 9.173,20.472 C9.173,21.122 8.658,21.660 8.000,21.695 Z',
  email: 'M19.034,3.725 C19.269,3.543 19.604,3.604 19.760,3.827 C19.937,4.069 19.878,4.414 19.643,4.576 L11.314,10.812 C11.117,10.954 10.882,10.934 10.705,10.812 L2.357,4.576 C2.121,4.414 2.082,4.070 2.239,3.827 C2.416,3.604 2.730,3.544 2.966,3.725 L11.000,9.718 L19.034,3.725 ZM2.102,-0.000 L19.898,-0.000 C20.487,-0.000 21.018,0.243 21.391,0.647 C21.764,1.032 22.000,1.579 22.000,2.167 L22.000,15.834 C22.000,16.420 21.764,16.967 21.391,17.373 C20.998,17.757 20.487,18.000 19.898,18.000 L2.102,18.000 C1.532,18.000 1.002,17.757 0.629,17.373 C0.236,16.967 -0.000,16.441 -0.000,15.834 L-0.000,2.167 C-0.000,1.579 0.236,1.032 0.629,0.647 C1.002,0.243 1.532,-0.000 2.102,-0.000 L2.102,-0.000 ZM19.898,1.073 L2.102,1.073 C1.807,1.073 1.551,1.194 1.355,1.396 C1.159,1.599 1.041,1.863 1.041,2.167 L1.041,15.834 C1.041,16.137 1.159,16.420 1.355,16.603 C1.551,16.806 1.807,16.927 2.102,16.927 L19.898,16.927 C20.193,16.927 20.468,16.806 20.664,16.603 C20.840,16.420 20.959,16.137 20.959,15.834 L20.959,2.167 C20.959,1.863 20.840,1.599 20.664,1.396 C20.468,1.194 20.193,1.073 19.898,1.073 L19.898,1.073 ZM10.705,10.812 C10.646,10.772 10.607,10.731 10.587,10.691',
  company: 'M21.522,21.000 L20.520,21.000 L13.506,21.000 L13.491,21.000 L1.507,21.000 L0.478,21.000 C0.214,21.000 -0.000,20.789 -0.000,20.528 C-0.000,20.267 0.214,20.056 0.478,20.056 L0.997,20.056 L0.997,4.138 C0.997,3.691 1.314,3.298 1.777,3.170 L12.593,0.045 C12.925,-0.051 13.288,0.005 13.568,0.196 C13.849,0.387 14.015,0.690 14.016,1.013 L14.016,6.259 L20.604,11.137 C20.865,11.330 21.017,11.627 21.016,11.941 L21.016,20.056 L21.522,20.056 C21.786,20.056 22.000,20.267 22.000,20.528 C22.000,20.789 21.786,21.000 21.522,21.000 ZM20.024,11.941 C20.024,11.919 20.013,11.898 19.995,11.885 L14.016,7.459 L14.016,20.054 L20.024,20.054 L20.024,11.941 ZM12.985,1.013 C12.986,0.991 12.975,0.969 12.955,0.956 C12.934,0.947 12.909,0.947 12.889,0.956 L2.078,4.072 C2.048,4.082 2.028,4.108 2.027,4.138 L2.017,20.056 L12.985,20.056 L12.985,1.013 ZM5.036,6.026 L10.005,6.026 C10.269,6.026 10.483,6.238 10.483,6.498 C10.483,6.759 10.269,6.970 10.005,6.970 L5.036,6.970 C4.772,6.970 4.557,6.759 4.557,6.498 C4.557,6.238 4.772,6.026 5.036,6.026 ZM5.036,10.303 L10.005,10.303 C10.269,10.303 10.483,10.515 10.483,10.775 C10.483,11.036 10.269,11.247 10.005,11.247 L5.036,11.247 C4.772,11.247 4.557,11.036 4.557,10.775 C4.557,10.515 4.772,10.303 5.036,10.303 ZM5.036,14.585 L10.005,14.585 C10.269,14.585 10.483,14.796 10.483,15.057 C10.483,15.318 10.269,15.529 10.005,15.529 L5.036,15.529 C4.772,15.529 4.557,15.318 4.557,15.057 C4.557,14.796 4.772,14.585 5.036,14.585 ZM15.189,14.585 L17.920,14.585 C18.184,14.585 18.398,14.796 18.398,15.057 C18.398,15.318 18.184,15.529 17.920,15.529 L15.189,15.529 C14.925,15.529 14.711,15.318 14.711,15.057 C14.711,14.796 14.925,14.585 15.189,14.585 Z',
  more: 'M2887.526491 1023.979521h323.156303l33.380531-30.71828a104.032574 104.032574 0 0 0 0-151.543513L2373.097699 30.755142a117.958194 117.958194 0 0 0-81.915413-30.71828 116.524675 116.524675 0 0 0-81.915412 30.71828 98.298495 98.298495 0 0 0-32.356589 75.771756 102.394266 102.394266 0 0 0 32.766166 75.771757l677.030886 628.700793H113.665827a113.657635 113.657635 0 0 0-80.277104 30.71828 99.936804 99.936804 0 0 0-33.380531 75.771757 102.394266 102.394266 0 0 0 33.585319 75.771756 114.476789 114.476789 0 0 0 80.277105 30.71828H2887.526491z'
}
