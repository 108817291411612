import user from './model/user'
import product from './model/product'
import gpus from './model/gpus'
import news from './model/news'
import content from './model/content'
const api = {
  ...user,
  ...product,
  ...gpus,
  ...news,
  ...content
}

export default api
