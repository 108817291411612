<template>
  <footer class="footer">
    <div class="contact-group">
      <div class="top">
        <p class="phone">
          <span>咨询热线：010-62560919</span>
        </p>
        <!-- <router-link class="btn" to="/product?pos=trial">
            购买/试用
          </router-link> -->
      </div>
    </div>
    <div class="nav-wrapper">
      <section class="nav-list">
        <template v-for="nav of navs">
          <!-- 解决方案这种动态的子menu不渲染在footer -->
          <template v-if="!nav.meta.dynamicChildren">
            <router-link v-if="!nav.children" class="nav-item" :to="nav.path" :key="nav.path">
              {{ nav.title }}
            </router-link>
            <template v-for="(child, subIndex) of nav.children">
              <router-link class="nav-item" v-if="!child.meta.isExternalLink" :to="nav.path + '/' + child.path"
                :key="child.meta.title + subIndex">
                {{ child.meta.title }}
              </router-link>
              <a class="nav-item" v-else :href="child.path" :key="child.meta.title">
                {{ child.meta.title }}
              </a>
            </template>
          </template>
        </template>
      </section>
      <a class="nav-item-other" @click="toPartnerPortal">合作伙伴</a>
    </div>
    <div class="attention">
      <div class="img-box">
        <img src="@/assets/images/footer/qrcode.png" alt="二维码" />
      </div>
      <p class="tip">长按识别或截图保存，关注趋动科技公众号</p>
    </div>
  </footer>
</template>

<script>
import pageMixin from '@/mixins/pageMixin'
import { mapGetters } from 'vuex'
export default {
  mixins: [pageMixin],
  data () {
    return {
      curIndex: -1,
      activeName: ''
    }
  },
  computed: {
    ...mapGetters(['navs'])
  },
  methods: {
    toPartnerPortal () {
      location.href = appEnv.config.partnerPortal
    }
  }
}
</script>

<style lang="less" scoped>
.footer {
  padding-top: 50px;
  background: #f5f9ff;

  .nav-wrapper {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);

    .nav-list {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      height: 168px;
      // padding: 0 30px;
      margin: 0 30px;

      .nav-item {
        margin: 20px 0;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        line-height: 44px;
      }
    }

    .nav-item-other {
      margin: 20px 0 20px 30px;
      display: inline-block;
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      line-height: 44px;
    }
  }

  .attention {
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    margin: 16px 30px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);

    .img-box {
      width: 156px;
      height: 156px;

      img {
        width: 100%;
      }
    }

    .tip {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      line-height: 40px;
      margin-left: 24px;
    }
  }

  .contact-group {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    padding-bottom: 24px;
    margin: 0 30px;

    .top {
      display: flex;
      justify-content: center;

      .phone {
        display: flex;
        // align-items: center;
        font-size: 28px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        line-height: 44px;

        &::before {
          content: "";
          display: block;
          width: 36px;
          height: 36px;
          margin-top: 2px;
          margin-right: 16px;
          background: url("~@/assets/images/footer/icon-phone.png") no-repeat;
          background-size: 100%;
        }
      }

      .btn {
        .radius_button(200px, 64px, #0073e6);
        margin-left: 15px;
        color: #fff;
      }
    }
  }
}
</style>
