// import qs from 'qs'
import axios from 'axios'
const baseConfig = {}

export default class HttpUtil {
  constructor (config) {
    this._http = this._createHttp(Object.assign(baseConfig, config))
  }

  get instance () {
    return this._http
  }

  get (url, params = {}) {
    return this._http.get(url, { params }).then(this._handleResult)
  }

  post (url, params = {}) {
    return this._http.post(url, params).then(this._handleResult)
  }

  patch (url, params = {}) {
    return this._http.patch(url, params).then(this._handleResult)
  }

  postForm (url, params = {}) {
    return this._http.post(url, params, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(this._handleResult)
  }

  _createHttp (config) {
    return axios.create(config)
  }

  _handleResult (res) {
    return res
  }
}
