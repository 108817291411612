import api from '@/store/api'
import { defaultContentIndex, defaultContentAbout, defaultContentEnglish, defaultContentContact } from '@/utils/defaultData'
export default {
  state: {
    isIndexFirst: true,
    isAboutFirst: true,
    isJobFirst: true,
    isEnglishFirst: true,
    isContactFirst: true,
    isDocFirst: true,
    contentIndex: defaultContentIndex, // 默认值
    contentAbout: defaultContentAbout,
    contentJob: [],
    contentEnglish: defaultContentEnglish,
    contentContact: defaultContentContact,
    contentDoc: []
  },
  mutations: {
    setContent (state, payload) {
      state[`content${payload.key}`] = payload.value
      state[`is${payload.key}First`] = payload.flag
    }
  },
  actions: {
    async fetchContentByKey ({ commit, state }, key) {
      if (!state[`is${key}First`]) return
      // 当传入的key值为'index'时，就发送fetchindexContent的请求
      const content = await api[`fetch${key}Content`]()
      for (const i in content) {
        // 当接口返回的某一项数据没有时，则取state中的已有值（默认值）
        if (!content[i]) {
          content[i] = state[`content${key}`][i]
        }
      }
      // 将异步获取的content值，commit到setContent这一mutation中
      commit('setContent', { value: content, key: key, flag: false })
    }
  }
}
