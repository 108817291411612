import base from './base'

export default {
  fetchGpus (userOfferId) {
    return base.get('/Gpus', { userOfferId })
  },
  postGpus (params) {
    return base.post('/Gpus', params)
  },
  getLicenses (userOfferId, isOffline) {
    return base.get('/Licenses', { userOfferId, isOffline }, { responseType: 'blob' })
  }
}
