export const defaultContentIndex = {
  banner: {
    backGroundImgUrl: require('@/assets/images/home/banner.jpg'),
    title: 'Orion',
    subTitle: 'OrionX计算平台（猎户座计算平台）是一个为云或者数据中心内的AI应用，CUDA应用提供GPU资源池化以及GPU虚拟化能力的系统软件。'
  },
  produceDes: {
    produceItems: [
      {
        icon: require('@/assets/images/home/icon_vgpu_5.png'),
        content: '通过统一管理GPU，减轻GPU的管理复杂度和成本。',
        contentItemId: '41zjxae9v0t0r48zzwkaj2vndd'
      },
      {
        icon: require('@/assets/images/home/icon_vgpu_4.png'),
        content: '通过对GPU资源池的管理和优化，提高整个云和数据中心GPU的利用率和吞吐率。',
        contentItemId: '4en7y67es558d1z8bwpy4jptnf'
      },
      {
        icon: require('@/assets/images/home/icon_vgpu_3.png'),
        content: '兼容已有的AI应用和CUDA应用，使其仍然具有使用GPU加速的性能。',
        contentItemId: '40n0ypdgv82y4rn0wrwqrgweck'
      },
      {
        icon: require('@/assets/images/home/icon_vgpu_2.png'),
        content: 'OrionX GPU资源随AI应用和CUDA应用启动时分配，随应用程序退出时自动释放。减少GPU空闲时间，提高共享GPU的周转率。',
        contentItemId: '4er23eeabyd4m321z7x4bfsxcr'
      },
      {
        icon: require('@/assets/images/home/icon_vgpu_1.png'),
        content: '为AI应用和CUDA应用在云和数据中心的部署提供了很大的灵活度。无需受GPU服务器位置、资源数量的约束。',
        contentItemId: '42d1cfnm1jchqzb1h1awjf1cmy'
      }
    ],
    produceSubject: {
      imgUrl: require('@/assets/images/home/vGPU2.5d.png'),
      title: 'OrionX计算平台',
      contentItemId: '46096vkczrkrdr1kany97jqynv',
      content: 'OrionX计算平台（猎户座计算平台）可以在几乎零性能损失的情况下，提供对于物理GPU的细粒度、可远程、运行时动态可配置的虚拟化方案。通过高效的通讯机制，使得AI应用，CUDA应用可以运行在云或者数据中心内任何一个物理机，Container或者VM内而无需挂载物理GPU，同时为这些应用程序提供在GPU资源池中的硬件算力。'
    }
  },
  examples: [
    {
      coverImg: require('@/assets/images/home/finance_img.jpg'),
      title: '金融大数据',
      id: 24
    },
    {
      coverImg: require('@/assets/images/home/manufacture_img.jpg'),
      title: '自动驾驶',
      id: 26
    },
    {
      coverImg: require('@/assets/images/home/education_img.jpg'),
      title: '高校AI实训平台、生物工程',
      id: 20
    },
    {
      coverImg: require('@/assets/images/home/website_img.jpg'),
      title: 'AI推理训练计算平台',
      id: 18
    }
  ],
  news: [
    {
      contentItemId: '411x0fq8q7kvd7nqecqwpnb4wk',
      title: '与戴尔科技集团签署战略合作备忘录',
      authtor: '作者',
      // coverImgUrl: require('@/assets/images/newsData/image003.jpg'),
      decrible: '2019年12月16日，戴尔科技集团与中国本土AI技术平台合作伙伴— 北京趋动科技有限公司签署战略合作备忘录......'
    },
    {
      contentItemId: '4wtrzhh1wanwm0hf7rdg7gfaxm',
      title: '完成天使轮融资',
      authtor: '作者',
      // coverImgUrl: require('@/assets/images/newsData/image002.jpg'),
      decrible: '趋动科技完成天使轮融资。资本看重5G和AI时代的虚拟化软件市场，本轮融资由涌铧投资领投，深鉴科技CEO姚颂跟投。\r\n\r\n趋动科技由Dell EMC中国研究院背景的核心团队创办，主要为用户提供AI加速器虚拟化和资源池化软件及解决方案。目前趋动科技已经推出OrionX计算平台，使用户应用无需修改就能透明地共享和使用数据中心内任一服务器之上的AI加速器。趋动科技也已经开始与戴尔科技合作，推出了数据中心级加速器资源池的软硬件一体解决方案。同时，已有多家公有云，互联网，AI的头部客户开始使用OrionX计算平台。'
    },
    {
      contentItemId: '4rqpr0rky6e9xzq2aqgy4b335v',
      title: 'OrionX GPU资源调度器计算机软件著作权',
      authtor: '作者',
      // coverImgUrl: require('@/assets/images/newsData/image001.jpg'),
      decrible: '趋动科技获中华人民共和国国家版权局颁发计算机软件著作权登记证书，自主研发的OrionX GPU资源调度器软件正式登记。'
    },
    {
      contentItemId: '44tb3padtfvgh79bv3xest8g5d',
      title: '北京趋动科技有限公司成立',
      authtor: '作者',
      // coverImgUrl: require('@/assets/images/newsData/image004.jpg'),
      decrible: '北京趋动科技有限公司成立于2019年4月，由多位具有世界500强中国研究院负责人背景并且具有共同远大理想的高科技人才创立，专注于为全球用户提供国际领先的数据中心级AI加速器虚拟化和资源池化软件及解决方案。\r\n\r\n基于创新的技术，趋动科技的猎户座AI计算平台帮助客户构建数据中心级AI加速器资源池，使用户应用无需修改就能透明地共享和使用数据中心内任何服务器之上的AI加速器，不但能够帮助用户提高资源利用率，而且可以极大便利用户应用的部署。目前已有多家人工智能，互联网和公有云的头部企业开始使用猎户座AI计算平台。\r\n趋动科技秉承踏实严谨的工作作风，以“软件推动进步，创新实现高效”为己任，扎根本土，放眼世界，携手合作伙伴共创未来，不断丰富企业文化，继续树立良好的企业社会形象，力争成为业界领先的国产虚拟化软件公司。'
    }
  ]
}

export const defaultContentAbout = {
  companyIntro: {
    backgroundImgUrl: require('@/assets/images/about/banner.jpg'),
    bottomImgUrl: require('@/assets/images/about/bg_compute.jpg'),
    content: '北京趋动科技有限公司成立于2019年4月，由多位具有世界500强中国研究院负责人背景并且具有共同远大理想的高科技人才创立，专注于为全球用户提供国际领先的数据中心级AI加速器虚拟化和资源池化软件及解决方案。\r\n\r\n基于创新的技术，趋动科技的猎户座AI计算平台帮助客户构建数据中心级AI加速器资源池，使用户应用无需修改就能透明地共享和使用数据中心内任何服务器之上的AI加速器，不但能够帮助用户提高资源利用率，而且可以极大便利用户应用的部署。目前已有多家人工智能，互联网和公有云的头部企业开始使用猎户座AI计算平台。\r\n\r\n趋动科技秉承踏实严谨的工作作风，以“软件推动进步，创新实现高效”为己任，扎根本土，放眼世界，携手合作伙伴共创未来，不断丰富企业文化，继续树立良好的企业社会形象，力争成为业界领先的国产虚拟化软件公司。'
  },
  teamMembers: [
    {
      id: 1,
      name: '王鲲',
      position: '趋动科技CEO',
      titles: [
        '中国科学技术大学 博士',
        '前Dell EMC中国研究院  院长'
      ],
      // avatar: require('@/assets/images/about/avatar1.jpg'),
      description: '王鲲博士长期从事计算机体系结构，GPU和FPGA虚拟化，分布式系统等领域的研究工作，在业界率先开始推动FPGA虚拟化相关研究。'
    },
    {
      id: 2,
      name: '石凤华',
      position: '趋动科技COO',
      titles: [
        '中欧EMBA',
        '前Dell EMC 互联网行业销售总监'
      ],
      // avatar: require('@/assets/images/about/avatar2.jpg'),
      description: '石凤华女士带领Dell EMC互联网销售团队实现5年销售额10倍的增长，先后荣获Dell EMC主席俱乐部和总裁俱乐部成员奖。'
    },
    {
      id: 3,
      name: '陈飞',
      position: '趋动科技CTO',
      titles: [
        '中国科学院计算技术研究所 博士',
        '前Dell EMC中国研究院 首席科学家'
      ],
      // avatar: require('@/assets/images/about/avatar3.jpg'),
      description: '陈飞博士长期从事高性能计算，计算机体系结构，GPU和FPGA虚拟化等领域的研究工作, 是这些领域世界级专家。'
    },
    {
      id: 4,
      name: '邹懋',
      position: '趋动科技首席架构师',
      titles: [
        '中国科学技术大学 博士',
        '前Dell EMC中国研究院 高级研究员'
      ],
      // avatar: require('@/assets/images/about/avatar4.jpg'),
      description: '邹懋博士在计算机体系结构，GPU虚拟化等领域有多年研究经验，是这些领域世界级专家。'
    }
  ],
  timeLines: [
    {
      contentItemId: '48wmd6dp0n6417t3p47jqja2dh',
      date: '2019/04',
      keyWords: '公司成立',
      title: '北京趋动科技有限公司成立',
      content: '北京趋动科技有限公司成立于2019年4月，由多位具有世界500强中国研究院负责人背景并且具有共同远大理想的高科技人才创立，专注于为全球用户提供国际领先的数据中心级AI加速器虚拟化和资源管理调度软件及解决方案。',
      picture: require('@/assets/images/about/develop_img.jpg')
    },
    {
      contentItemId: '4d156bppvrt8t23z4se4ggmd5q',
      date: '2019/12',
      keyWords: '战略合作',
      title: '北京趋动科技与戴尔科技集团签署战略合作备忘录',
      content: '戴尔科技集团与中国本土AI技术平台合作伙伴— 北京趋动科技有限公司签署战略合作备忘录，共同合作为中国客户提供AI动态加速云解决方案。',
      picture: require('@/assets/images/about/develop_img2.jpg')
    }
  ]
}

export const defaultContentEnglish = {
  company: {
    contentItemId: '49dy119hmpsm7vaqkg3vwerqn5',
    banner: [
      {
        title: 'OrionX',
        backgroundImgUrl: require('@/assets/images/english/banner.jpg'),
        subTitle: 'OrionX software provides a fine-grained, remote, and dynamically configurable virtualization solution for physical GPUs with almost no performance loss.'
      },
      {
        title: 'GEMINI AI Platform',
        backgroundImgUrl: require('@/assets/images/english/banner_platform.png'),
        subTitle: 'The GEMINI AI Platform provides customers with powerful AI computing power management services and efficient algorithm development and training support.'
      },
      {
        title: 'VirtAI Cloud',
        backgroundImgUrl: require('@/assets/images/english/banner_cloud.png'),
        subTitle: 'Based on leading GPU virtualization technology, VirtAI Cloud connects global computing power to bring you a high-quality AI application development experience and greatly reduce your costs.'
      }
    ],
    bottomImgUrl: require('@/assets/images/english/bg_profile.jpg'),
    content: "Started in April 2019, Beijing VirtAI Technology Co., Ltd. was founded by a number of high-tech talents who were former heads of the China Research Institutes of the Fortune 500 and had a common vision, focusing on providing global leading data-center class AI accelerator virtualization and resource management software and solutions for customers cross the world.\r\n\r\n\r\nWith innovative technology, VirtAI Tech's OrionX AI computing platform helps customers build datacenter class AI accelerator resource pools, so that user applications can transparently share and use AI accelerators on any server in the data center without modification, which can not only help customers improve resource utilization and can greatly facilitate the deployment of user applications. A bunch of artificial intelligence, Internet and top public cloud companies have begun to use the Orion AI computing platform.\r\n\r\n\r\nAdhering to the rigorous working spirit, taking \"software to promote progress and innovation to achieve high efficiency\" as its mission, VirtAI Tech is serving customers from China to the whole world with its partners, enriching the corporate culture, continually establishing a good corporate social image, and trying to become the China's leading local virtualization software company."
  },
  contact: {
    contentItemId: '4xz3nbskyqc106vj9nrxtyjqwg',
    address: 'B-809，No.8，Haidian North 2nd Street，Beijing 100080，China',
    email: 'BD@virtaitech.com',
    number: '010-62560919',
    backgroundImgUrl: require('@/assets/images/english/map.jpg')
  },
  produce: {
    produceItems: [
      {
        icon: require('@/assets/images/home/icon_vgpu_1.png'),
        content: 'Provides great flexibility for the deployment of AI applications and CUDA applications in the cloud and data centers. There is no limit on the location of the GPU server and the number of resources.'
      },
      {
        icon: require('@/assets/images/home/icon_vgpu_2.png'),
        content: 'OrionX GPU resources are allocated when the AI application and CUDA application start, and are automatically released when the application exits. Reduce GPU idle time and improve the shared GPU turnover rate.'
      },
      {
        icon: require('@/assets/images/home/icon_vgpu_3.png'),
        content: 'Compatible with existing AI applications and CUDA applications, it still has the performance of using GPU acceleration.'
      },
      {
        icon: require('@/assets/images/home/icon_vgpu_4.png'),
        content: 'Through the management and optimization of GPU resource pools, the utilization rate and throughput rate of GPUs in the entire cloud and data center are improved.'
      },
      {
        icon: require('@/assets/images/home/icon_vgpu_5.png'),
        content: 'Through unified management of GPUs, GPU management complexity and cost are reduced.'
      }
    ],
    produceSubject: {
      imgUrl: require('@/assets/images/home/vGPU2.5d.png'),
      title: 'OrionX',
      contentItemId: '4t4z4qv5m5jj42jaaeanhbx0dc',
      content: 'OrionX software is a system software that provides GPU resource pooling and GPU virtualization capabilities for AI applications in cloud or data centers and CUDA applications. Through the efficient communication mechanism, AI applications and CUDA applications can run on any physical machine in the cloud or data center, Container or VM without the need to mount a physical GPU. At the same time provide these applications with hardware computing power in the GPU resource pool.'
    }
  },
  caseList: [
    {
      title: 'Media & Entertainment',
      desc: 'Increases GPU resource utilization by 200%+ in R&D scenarios. ',
      image: require('@/assets/images/english/case_migu.png'),
      contentItemId: '4hwbbbm251h5ys0jnzrp1gczna'
    },
    {
      title: 'Internet',
      desc: 'Automatically allocate and release OrionX vGPU resources,  and reducing maintenance and management cost.',
      image: require('@/assets/images/english/case_xiecheng.png'),
      contentItemId: '4gzdx6s9fqs0w4ntzyh9ca32n3'
    }
  ],
  news: [
    {
      contentItemId: '4gy2vwspbd2966qtf16h3zpa46',
      coverImgUrl: require('@/assets/images/english/news.png'),
      title: 'VIRTAITECH COMPLETES SEVERAL DOZENS OF MILLIONS USD SERIES B+ LED BY CDB INDUSTRIAL FUND',
      decrible: 'The sixth funding round completed since the company was established 3 years ago.',
      createdAt: '2021-12-20'
    },
    {
      contentItemId: '4gfbtwhcnq0es5z3jhfx98yrm5',
      coverImgUrl: 'https://orionx-dev-cms.virtaitech.com/Portal/media/news/20220708/喜报！趋动科技荣登2022年度“新锐100”榜单_cover.png',
      title: 'VIRTAITECH COMPLETES SEVERAL DOZENS OF MILLIONS USD SERIES B LED BY PROSPERITY7 VENTURES',
      decrible: 'Up to now, VirtAITech\'s OrionX software has been recognized by many industry-leading customers.',
      createdAt: '2021-07-05'
    }
  ],
  partners: [
    'https://cms.virtaitech.com/Portal/media/合作伙伴图片/logo_02.jpg',
    'https://cms.virtaitech.com/Portal/media/合作伙伴图片/logo_01.jpg',
    'https://cms.virtaitech.com/Portal/media/合作伙伴图片/logo_03.jpg',
    'https://cms.virtaitech.com/Portal/media/合作伙伴图片/logo_04.jpg',
    'https://cms.virtaitech.com/Portal/media/合作伙伴图片/logo_05.jpg',
    'https://cms.virtaitech.com/Portal/media/合作伙伴图片/logo_06.jpg',
    'https://cms.virtaitech.com/Portal/media/合作伙伴图片/logo_07.jpg',
    'https://cms.virtaitech.com/Portal/media/合作伙伴图片/logo_08.jpg',
    'https://cms.virtaitech.com/Portal/media/合作伙伴图片/logo_09.jpg',
    'https://cms.virtaitech.com/Portal/media/合作伙伴图片/logo_10.jpg',
    'https://cms.virtaitech.com/Portal/media/合作伙伴图片/logo_11.jpg',
    'https://cms.virtaitech.com/Portal/media/合作伙伴图片/logo_12.jpg',
    'https://cms.virtaitech.com/Portal/media/合作伙伴图片/logo_13.jpg',
    'https://cms.virtaitech.com/Portal/media/合作伙伴图片/logo_14.jpg',
    'https://cms.virtaitech.com/Portal/media/合作伙伴图片/logo_15.jpg',
    'https://cms.virtaitech.com/Portal/media/新闻/微信截图_20221019143222.png',
    'https://cms.virtaitech.com/Portal/media/新闻/微信截图_20221019145949.png',
    'https://cms.virtaitech.com/Portal/media/新闻/微信截图_20221019145431.png',
    'https://cms.virtaitech.com/Portal/media/合作伙伴图片/图片 1.png',
    'https://cms.virtaitech.com/Portal/media/合作伙伴图片/图片 11.png',
    'https://cms.virtaitech.com/Portal/media/合作伙伴图片/微信截图_20221101150832.png',
    'https://cms.virtaitech.com/Portal/media/合作伙伴图片/图片 14.png',
    'https://cms.virtaitech.com/Portal/media/合作伙伴图片/图片 16.png',
    'https://cms.virtaitech.com/Portal/media/合作伙伴图片/图片 17.png',
    'https://cms.virtaitech.com/Portal/media/合作伙伴图片/图片 2.png',
    'https://cms.virtaitech.com/Portal/media/合作伙伴图片/图片 3.png',
    'https://cms.virtaitech.com/Portal/media/合作伙伴图片/图片 7.png',
    'https://cms.virtaitech.com/Portal/media/合作伙伴图片/图片 8.png',
    'https://cms.virtaitech.com/Portal/media/合作伙伴图片/图片 9.png',
    'https://cms.virtaitech.com/Portal/media/合作伙伴图片/图片 13.png',
    'https://cms.virtaitech.com/Portal/media/合作伙伴图片/微信截图_20221101150808.png',
    'https://cms.virtaitech.com/Portal/media/合作伙伴图片/微信截图_20230207141704.png',
    'https://cms.virtaitech.com/Portal/media/合作伙伴图片/微信图片_20230112153537.png',
    'https://cms.virtaitech.com/Portal/media/合作伙伴图片/图片111.png',
    'https://cms.virtaitech.com/Portal/media/合作伙伴图片/微信图片_20221228104651.png'
  ]
}

export const defaultContentContact = {
  subContacts: [
    {
      contentItemId: '48rth6fhq6y8xwzjkvpf257vw0',
      address: '上海市普陀区凯旋北路1288号环球港B座16楼',
      location: '上海',
      telphone: '',
      emailResume: 'HR@virtaitech.com',
      emailCooperation: 'BD@virtaitech.com',
      mapImgUrl: null
    },
    {
      contentItemId: '4kvjpa2pfzw87rvy4dtn2k1enj',
      address: '北京市海淀区海淀北二街8号中关村SOHO809室',
      location: '北京',
      telphone: '010-62560919',
      emailResume: 'HR@virtaitech.com',
      emailCooperation: 'BD@virtaitech.com',
      mapImgUrl: null
    }
  ],
  defaultMapImgUrl: require('@/assets/images/contact/map.jpg'),
  defaultEmailResume: 'HR@virtaitech.com',
  defaultEmailCooperation: 'BD@virtaitech.com'
}
