import { cmsApi } from '../../utils/cms'
import _ from 'lodash'
import moment from 'moment'
import analyze from 'rgbaster'
import { defaultContentEnglish } from '@/utils/defaultData'
import bannerImg from '@/assets/images/english/en-banner.jpeg'
import bottomImg from '@/assets/images/english/en-bg_profile.jpeg'
import gpuImg from '@/assets/images/english/gpu.png'

export default {
  fetchIndexContent () {
    const data = cmsApi.getIndexPageInfomation().then(res => {
      const productInfoItems = _.get(
        res,
        'data.orionXInformation[0].list.contentItems',
        []
      )
      const customerCase = _.get(
        res,
        'data.customerCase[0].list.contentItems',
        []
      )
      const solutionItems = _.get(
        res,
        'data.industrySolutions[0].list.contentItems',
        []
      )
      const newsItems = _.get(res, 'data.news', [])
      const articleItems = _.get(res, 'data.article', [])
      const partnerImageList = _.get(
        res,
        'data.partnerImage[0].images.urls',
        []
      )
      const result = {
        banner: {
          desc: _.get(res, 'data.orionXInformation[0].introduction', '').replace('，用户应用', '\n用户应用')
        },
        productInfoList: productInfoItems.map(i => ({
          desc: i.content,
          title: i.title,
          logo: _.get(i.icon, 'urls[0]', '')
        })),
        caseList: customerCase.map(i => ({
          title: i.name,
          desc: i.introduction,
          image: _.get(i.coverImage, 'urls[0]', ''),
          contentItemId: i.contentItemId
        })),
        solutionList: solutionItems.map(i => ({
          industry: i.name,
          desc: i.introduction,
          image: _.get(i.backgroundImage, 'urls[0]', ''),
          pdfFileSrc: _.get(i.fileDescription, 'urls[0]', ''),
          maskStyle: ''
        })),
        news: newsItems.map(i => ({
          contentItemId: i.contentItemId,
          coverImgUrl: _.get(i, 'coverImage.url'),
          title: i.title,
          decrible: _.get(i, 'introduction', '-'),
          createdAt: i.publishedUtc
        })),
        article: articleItems.map(i => ({
          contentItemId: i.contentItemId,
          coverImgUrl: _.get(i, 'coverImage.url'),
          title: i.title,
          decrible: _.get(i, 'introduction', '-'),
          createdAt: i.publishedUtc
        })),
        partners: partnerImageList
      }
      result.solutionList.forEach(item => {
        analyze(item.image, { scale: 0.004 }).then(res => {
          item.maskStyle = res[0].color
        })
      })
      return result
    })
    return data
    // return base.get('/Content/index')
  },
  fetchAboutContent () {
    const data = cmsApi.getAboutCompany().then(res => {
      const info = {
        companyIntro: {
          content: _.get(res, 'data.companyInfo[0].introduction', '-')
        },
        teamMembers: _.get(
          res,
          'data.managementTeam[0].list.contentItems',
          []
        ).map(item => {
          return {
            // contentItemId:
            name: item.name,
            avatar: _.get(item, 'avatar.urls[0]'),
            // order:
            titles: _.get(item, 'titles.termContentItems', []).map(
              t => t.displayText
            ),
            description: item.description,
            position: item.position
          }
        })
      }
      return info
      // const data = await context.$axios.get('/Content/introduce')
    })
    return data
    // return base.get('/Content/introduce')
  },
  fetchNewsContent (params) {
    const { pageSize, pageIndex } = params
    const data = cmsApi.getNewsList(pageIndex, pageSize).then(res => {
      const totalPage = Math.ceil(
        _.get(res, 'data.newsCount[0].total', 0) / pageSize
      )
      const list = _.get(res, 'data.news', []).map(item => {
        return {
          title: item.title,
          coverImgUrl: _.get(item, 'coverImage.urls[0]', ''),
          createdAt: moment(item.publishedUtc).format('YYYY-MM-DD'),
          decrible: item.introduction,
          contentItemId: item.contentItemId
        }
      })
      return {
        newsItems: list,
        total: totalPage,
        currentPage: pageIndex,
        pageSize: pageSize
      }
    })
    return data
    // return base.get('/Content/news', params)
  },
  fetchNewsContentDetail (id) {
    const data = cmsApi.getNewsDetail(id).then(data => {
      const detail = _.get(data, 'data.news[0]')
      const preNews = _.get(data, 'data.newsPreviousItem[0]', null)
      const nextNews = _.get(data, 'data.newsNextItem[0]', null)
      return {
        contentItemId: detail.contentItemId,
        title: detail.title,
        author: '-',
        coverImgUrl: _.get(detail, 'coverImage.urls[0]'),
        createdAt: moment(detail.publishedUtc).format('YYYY-MM-DD'),
        describe: detail.introduction,
        content: _.get(detail, 'newsContent.html'),
        next: nextNews ? { contentItemId: nextNews.contentItemId, title: nextNews.title } : null,
        pre: preNews ? { contentItemId: preNews.contentItemId, title: preNews.title } : null
      }
    })
    return data
    // return base.get(`/Content/news/${id}`)
  },
  fetchArticleContent (params) {
    const { pageSize, pageIndex } = params
    const data = cmsApi.getTechPagesList(pageIndex, pageSize).then(res => {
      const totalPage = Math.ceil(
        _.get(res, 'data.articleCount[0].total', 0) / pageSize
      )
      const list = _.get(res, 'data.article', []).map(item => {
        return {
          title: item.title,
          coverImgUrl: _.get(item, 'coverImage.urls[0]', ''),
          createdAt: moment(item.publishedUtc).format('YYYY-MM-DD'),
          decrible: item.introduction,
          contentItemId: item.contentItemId
        }
      })
      return {
        articleItems: list,
        total: totalPage,
        currentPage: pageIndex,
        pageSize: pageSize
      }
    })
    return data
    // return base.get('/Content/article', params)
  },
  fetchArticleContentDetail (id) {
    const data = cmsApi.getTechPagesDetail(id).then(data => {
      const detail = _.get(data, 'data.article[0]')
      const preArticle = _.get(data, 'data.articlePreviousItem[0]', null)
      const nextArticle = _.get(data, 'data.articleNextItem[0]', null)
      return {
        contentItemId: detail.contentItemId,
        title: detail.title,
        author: '-',
        coverImgUrl: _.get(detail, 'coverImage.urls[0]'),
        createdAt: moment(detail.publishedUtc).format('YYYY-MM-DD'),
        describe: detail.introduction,
        content: _.get(detail, 'articleContent.html'),
        next: nextArticle ? { contentItemId: nextArticle.contentItemId, title: nextArticle.title } : null,
        pre: preArticle ? { contentItemId: preArticle.contentItemId, title: preArticle.title } : null
      }
    })
    return data
    // return base.get(`/Content/Article/${id}`)
  },
  fetchJobContent () {
    const data = cmsApi.getJobInfo().then(res => {
      const jobs = _.get(res, 'data.hiring[0].list.contentItems', []).map(
        item => {
          return {
            jobName: item.name,
            jobType: item.jobType,
            peoples: item.headCount,
            location: item.location,
            publishedTime: item.publishedDate,
            contactEmail: item.contactEmail,
            contactPhone: item.contactPhone,
            workContent: item.workContent,
            skills: item.skills
          }
        }
      )

      return {
        jobs,
        welfare: {
          title: '福利说明',
          iconUrl:
            _.get(res, 'data.employeeWelfare[0].welfareImage.urls[0]', ''),
          content: _.get(res, 'data.employeeWelfare[0].description', '-')
        }
      }
    })
    return data
    // return base.get('/Content/job')
  },
  fetchEnglishContent () {
    const data = cmsApi.getEnglishPage().then(res => {
      const info = _.get(res, 'data.englishPage[0]')
      return {
        contact: {
          address: info.address,
          email: info.email,
          number: info.phone,
          backgroundImgUrl: _.get(info, 'map.urls[0]')
        },
        company: {
          title: 'OrionX',
          backgroundImgUrl: bannerImg,
          subTitle: info.introduce,
          bottomImgUrl: bottomImg,
          content: info.companyDescription,
          banner: defaultContentEnglish.company.banner
        },
        produce: {
          produceItems: _.get(info, 'list.contentItems', []).map(item => {
            return {
              icon: _.get(item, 'icon.urls[0]'),
              content: item.content
            }
          }),
          produceSubject: {
            content: info.orionXDetail,
            imgUrl: gpuImg,
            title: 'OrionX'
          }
        },
        caseList: defaultContentEnglish.caseList,
        news: defaultContentEnglish.news,
        partners: defaultContentEnglish.partners
      }
    })
    return data
    // return base.get('/Content/english')
  },
  fetchContactContent () {
    const data = cmsApi.getContactList().then(res => {
      const list = _.get(
        res,
        'data.companyContact[0].list.contentItems',
        []
      ).map(item => {
        return {
          address: item.address,
          location: item.name,
          telphone: item.phone
        }
      })

      return {
        subContacts: list,
        defaultEmailResume: _.get(res, 'data.webInfo[0].hREmail', '-'),
        defaultEmailCooperation: _.get(res, 'data.webInfo[0].bDEmail', '-'),
        wechatGzh:
          _.get(res, 'data.webInfo[0].wechatPublicAccount.urls[0]', '-'), // 公众号
        bdWechat:
          _.get(res, 'data.webInfo[0].bDWechat.urls[0]', '-') // 商务合作微信
      }
    })
    return data
    // return base.get('/Content/contact')
  },
  fetchDocContent () {
    const data = cmsApi.getDevelopDocList().then(async res => {
      const rawDocs = _.get(res, 'data.documentLibrary', [])
      const docs = rawDocs.map(d => {
        const docItemList = _.get(d, 'list.contentItems')
        return {
          items: docItemList.map(i => ({
            documentId: i.contentItemId,
            name: i.documentTitle
          })),
          category: docItemList[0].category
        }
      })
      return docs
    })
    return data
    // return base.get('/Content/techDocument')
  },
  fetchDocDetailContent (id) {
    const data = cmsApi.getDevelopDocDetail(id).then(data => {
      const mdData = _.get(data, 'data.document[0].content.markdown')
      return mdData
    })
    return data
    // return base.get(`/Content/techDocument/${id}`)
  }
}
