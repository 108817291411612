import Vue from 'vue'
import router from './index'
import { TOKEN } from '@/utils/variable'
import { setDocumentTitle } from '@/utils/domUtil'

router.beforeEach((to, from, next) => {
  // set title
  const title = (to.meta && to.meta.title) ? to.meta.title : ''
  setDocumentTitle(title)

  const hasSignup = (to.fullPath.includes('signin') || to.fullPath.includes('signup') || to.fullPath.includes('403')) && Vue.ls.get(TOKEN)
  if (hasSignup) {
    next({
      path: '/user'
    })
  }

  const noAuth = to.path.includes('user') && !Vue.ls.get(TOKEN)
  if (noAuth) {
    next({
      path: '/account/signin',
      query: {
        redirect: to.fullPath
      }
    })
  }

  next()
  if (to.path && window._hmt) {
    window._hmt.push(['_trackPageview', '/#' + to.fullPath])
  }
})
