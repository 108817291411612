const types = {
  403: {
    img: require('@/assets/images/exception/403.png'),
    title: '对不起，您无权访问该页面',
    desc: 'Sorry,you have no right to access this page'
  },
  404: {
    img: require('@/assets/images/exception/404.png'),
    title: '对不起，当前页面不存在',
    desc: 'Sorry, page not found'
  },
  500: {
    img: require('@/assets/images/exception/500.png'),
    title: '对不起，服务器遇到点小问题',
    desc: 'Sorry, the server encountered a small problem'
  }
}

export default types
