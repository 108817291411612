import base from './base'

export default {
  signup (params) {
    return base.post('/Users', params)
  },
  getSms (phone) {
    return base.post('/Sms/Send', { phone })
  },
  validateSms (params) {
    return base.post('/Sms/validate', params)
  },
  sendEmail (params) {
    return base.post('/Email/send', params)
  },
  validateEmail (uniqueStr) {
    return base.post('/Email/validate', { uniqueStr })
  },
  signin (params) {
    return base.post('/Tokens/Sms', params)
  },
  fetchUserInfo () {
    return base.get('/Users')
  }
}
