<template>
  <div class="home">
    <section class="banner-wrapper">
      <swiper ref="mySwiper" :options="bannerSwiperOptions">
        <swiper-slide>
          <section class="banner virtaitech-cloud-bg">
            <hgroup>
              <h1>趋动云AI平台</h1>
              <h3>基于领先的 GPU 虚拟化技术，连接全球算力，为您带来高质量的 AI 应用开发体验，大幅降低您的模型训练成本</h3>
            </hgroup>
            <div class="banner-btns">
              <a
                href="https://www.virtaicloud.com/"
                class="product"
                target="_blank"
                >了解更多</a
              >
            </div>
          </section>
        </swiper-slide>
        <swiper-slide v-if="false">
          <section class="banner">
            <hgroup>
              <h1>OrionX</h1>
              <h3
                v-html="
                  $options.filters.changeLine(contentIndex.banner.desc || '')
                "
              ></h3>
            </hgroup>
            <div class="banner-btns">
              <a
                :href="`${publicPath}product.pdf`"
                class="product"
                target="_blank"
                >了解更多</a
              >
            </div>
          </section>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </section>

    <!-- 产品介绍 -->
    <section class="row-section">
      <h3 class="row-title">软件定义算力，趋动赋能AI</h3>
      <section class="row-content">
        <div
          class="desc-item"
          v-for="(item, index) of contentIndex.productInfoList"
          :key="index"
        >
          <img :src="item.logo" alt="产品介绍图标" />
          <div class="solution-item-info">
            <h4>{{ item.title }}</h4>
            <p>{{ item.desc }}</p>
          </div>
        </div>
      </section>
    </section>
    <!-- 客户案例 -->
    <section class="customer-case row-section grey-bg">
      <h3 class="row-title">客户案例</h3>
      <section
        style="width: 100%;"
        v-if="contentIndex.caseList && contentIndex.caseList.length > 0"
      >
        <swiper ref="mySwiper" :options="swiperOptions">
          <swiper-slide
            v-for="(item, index) of contentIndex.caseList"
            :key="index"
          >
            <div
              class="slide-item"
              @click="
                $router.push({
                  path: '/case/detail',
                  query: { id: item.contentItemId }
                })
              "
            >
              <div class="image-wrapper">
                <img :src="item.image" :alt="item.title" />
              </div>
              <div class="desc-info">
                <h4>{{ item.title }}</h4>
                <p class="info-subtitle" ref="caseDesc">{{ item.desc }}</p>
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </section>
    </section>

    <!-- 新闻资讯 -->
    <section class="row-section">
      <h3 class="row-title">新闻资讯</h3>
      <section class="news">
        <ul class="news-list">
          <li
            class="news-item"
            :class="index === 0 ? '' : 'van-hairline--top'"
            v-for="(item, index) of contentIndex.news"
            :key="item.contentItemId"
          >
            <router-link
              class="news-link"
              :to="'/company/news/detail?id=' + item.contentItemId"
            >
              <div class="time">
                <h3>{{ item.createdAt | getDay }}</h3>
                <p>{{ item.createdAt | getTimeExceptDay }}</p>
              </div>
              <div class="detail">
                <h3>{{ item.title }}</h3>
                <p>{{ item.decrible }}</p>
              </div>
            </router-link>
          </li>
        </ul>
        <router-link class="more" to="/company/news/list">更多资讯</router-link>
      </section>
    </section>

    <!-- 技术文章 -->
    <section class="row-section">
      <h3 class="row-title">技术文章</h3>
      <section class="article">
        <ul class="article-list">
          <li
            class="article-item"
            :class="index === 0 ? '' : 'van-hairline--top'"
            v-for="(item, index) of contentIndex.article"
            :key="item.contentItemId"
          >
            <router-link
              class="article-link"
              :to="'/company/techPages/detail?id=' + item.contentItemId"
            >
              <div class="time">
                <h3>{{ item.createdAt | getDay }}</h3>
                <p>{{ item.createdAt | getTimeExceptDay }}</p>
              </div>
              <div class="detail">
                <h3>{{ item.title }}</h3>
                <p>{{ item.decrible }}</p>
              </div>
            </router-link>
          </li>
        </ul>
        <router-link class="more" to="/company/techPages/list">更多文章</router-link>
      </section>
    </section>

    <!-- 合作伙伴 -->
    <section class="row-section grey-bg">
      <h3 class="row-title">合作伙伴</h3>
      <section class="partner">
        <ul class="partner-list" v-if="contentIndex.partners">
          <li
            v-for="(item, index) of contentIndex.partners"
            :key="index"
            class="item"
          >
            <img :src="item" alt="合作伙伴Logo" />
          </li>
        </ul>
      </section>
    </section>
  </div>
</template>

<script>
import { publicPath } from '@/utils/variable'
import pageMixin from '@/mixins/pageMixin'
import { mapActions, mapGetters } from 'vuex'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
const regNumber = /\d+/g
export default {
  name: 'Home',
  mixins: [pageMixin],
  components: {
    SwiperSlide,
    Swiper
  },
  data () {
    return {
      swiperAboutCurrent: 0,
      publicPath,
      bannerSwiperOptions: {
        allowTouchMove: false,
        slidesPerView: 'auto',
        // autoplay: true,
        loop: true,
        initialSlide: 0,
        preventClicks: false
        // pagination: {
        //   el: '.swiper-pagination',
        //   bulletClass: 'my-bullet',
        //   bulletActiveClass: 'my-bullet-active'
        // }
      },
      swiperOptions: {
        slidesPerView: 'auto',
        autoplay: true,
        loop: true,
        initialSlide: 0,
        spaceBetween: 10,
        centeredSlides: true,
        preventClicks: false,
        pagination: {
          el: '.swiper-pagination',
          bulletClass: 'my-bullet',
          bulletActiveClass: 'my-bullet-active'
        }
      }
    }
  },
  filters: {
    getDay (value) {
      if (!value) return ''
      return value.match(regNumber)[2]
    },
    getTimeExceptDay (value) {
      if (!value) return ''
      const matches = value.match(regNumber)
      return matches[0] + '-' + matches[1]
    }
  },
  computed: {
    ...mapGetters(['contentIndex'])
  },
  methods: {
    ...mapActions(['fetchContentByKey']),
    handleChange (index) {
      this.swiperAboutCurrent = index
    },
    toPdf (src) {
      window.open(src)
    }
  },
  created () {
    this.fetchContentByKey('Index')
  },
  mounted () {
    const caseDescDom = this.$refs.caseDesc
    if (caseDescDom) {
      let maxHeight = 0
      caseDescDom.forEach(dom => {
        if (dom.clientHeight > maxHeight) {
          maxHeight = dom.clientHeight
        }
      })
      caseDescDom.forEach(dom => {
        dom.style.height = maxHeight + 'px'
      })
    }
  }
}
</script>
<style lang="less">

.banner-wrapper {
  margin-top: -@header-height;
  .swiper-container-horizontal>.swiper-pagination-bullets {
    bottom: 30px;
  }
}

.customer-case {
  .swiper-container {
    width: 100%;
    height: 100%;
    .slide-item {
      height: 100%;
      width: 100%;
    }
  }
  .swiper-slide {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.grey-bg {
  background: #fafafa;
}
.my-bullet {
  display: inline-block;
  width: 32px;
  height: 4px;
  background: #ced2e6;
  position: relative;
  margin: 30px 10px 0;
  transition: 0.2s;
}
.my-bullet-active {
  width: 64px;
  background: #0073e6;
}
.home {
  .banner {
    .flex(@direction: column);
    width: 100%;
    height: 620px;
    background-image: url("~@/assets/images/banner_moblie.png");
    &.virtaitech-cloud-bg {
      background-image: url("~@/assets/images/banner_mobile2.png");
    }
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: #fff;
    text-align: center;
    // margin-top: -@header-height;
    hgroup {
      max-width: 650px;
      margin: @header-height auto 0;
    }
    h1 {
      font-size: 80px;
      font-weight: 600;
    }
    h3 {
      text-align: left;
      font-size: 28px;
      line-height: 44px;
      margin: 34px 0 32px;
    }
    a {
      .radius_button;
    }
    .banner-btns {
      display: flex;
      .product {
        background: rgba(255, 255, 255, 0.1);
        border: 1px solid #fff;
        margin-left: 10px;
        border-radius: 0;
        width: 272px;
        height: 80px;
      }
    }
  }
  .row-section {
    padding: 32px 0 48px;
    .row-title {
      margin: 0 0 32px 0;
      font-size: 36px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 52px;
      text-align: center;
    }
    .row-content {
      .desc-item {
        padding: 0 30px;
        display: flex;
        margin-top: 48px;
        img {
          height: 38px;
          width: 38px;
        }
        .solution-item-info {
          margin-left: 40px;
          h4 {
            font-size: 28px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
            line-height: 44px;
          }
          p {
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.45);
            line-height: 40px;
            margin-top: 12px;
          }
        }
      }
    }
    .slide-item {
      width: 100%;
      border-radius: 10px;
      padding-bottom: 52px;
      overflow: hidden;
      .image-wrapper {
        width: 100%;
        height: 352px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .desc-info {
        width: 100%;
        background: #ffffff;
        border-radius: 0px 0px 8px 8px;
        border: 1px solid #ededed;
        border-top: none;
        padding: 24px;
        h4 {
          font-size: 28px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          line-height: 44px;
        }
        p {
          font-size: 24px;
          margin-top: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.45);
          line-height: 40px;
          height: 120px;
          overflow: hidden;
        }
      }
    }
    .solution-item {
      height: 236px;
      margin: 0 30px 24px 30px;
      border-radius: 8px;
      overflow: hidden;
      position: relative;
      .bg-image {
        width: 100%;
        height: 236px;

        position: absolute;
        top: 0;
        right: 0;
        transition: 0.1s;
      }
      .img-mask {
        width: 100%;
        height: 236px;
        position: absolute;
        top: 0;
        right: 0;
        .info-wrapper {
          color: #ffffff;
          margin-left: 40px;
          position: absolute;
          bottom: 68px;
          .info-title {
            font-size: 32px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 48px;
          }
          .info-desc {
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 40px;
            margin-top: 12px;
          }
        }
      }
    }
    .news {
      .news-list {
        padding: 0 30px 0;
        margin-top: -12px;
        .news-item {
          padding: 30px 0;
          color: @grey;
          .time {
            text-align: center;
            margin-right: 25px;
            color: @blue;
            h3 {
              font-size: 60px;
              font-weight: 600;
              margin-bottom: 5px;
            }
            p {
              font-size: 20px;
              white-space: nowrap;
            }
          }
          .detail {
            h3 {
              color: @black;
              font-size: 28px;
              margin-bottom: 15px;
              font-weight: 600;
              line-height: 36px;
            }
            p {
              font-size: 24px;
              line-height: 36px;
              max-height: 72px;
              .textEllipsisMulti(2);
            }
          }
        }
        .news-link {
          .flex(@justify: flex-start);
        }
      }
      .more {
        width: 272px;
        height: 64px;
        border-radius: 2px;
        border: 2px solid #0073e6;
        color: rgba(0, 115, 230, 1);
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #0073e6;
        line-height: 60px;
        display: block;
        text-align: center;
        margin: 0 auto;
        margin-top: 18px;
      }
    }
    .article {
      .article-list {
        padding: 0 30px 0;
        margin-top: -12px;
        .article-item {
          padding: 30px 0;
          color: @grey;
          .time {
            text-align: center;
            margin-right: 25px;
            color: @blue;
            h3 {
              font-size: 60px;
              font-weight: 600;
              margin-bottom: 5px;
            }
            p {
              font-size: 20px;
              white-space: nowrap;
            }
          }
          .detail {
            h3 {
              color: @black;
              font-size: 28px;
              margin-bottom: 15px;
              font-weight: 600;
              line-height: 36px;
            }
            p {
              font-size: 24px;
              line-height: 36px;
              max-height: 72px;
              .textEllipsisMulti(2);
            }
          }
        }
        .article-link {
          .flex(@justify: flex-start);
        }
      }
      .more {
        width: 272px;
        height: 64px;
        border-radius: 2px;
        border: 2px solid #0073e6;
        color: rgba(0, 115, 230, 1);
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #0073e6;
        line-height: 60px;
        display: block;
        text-align: center;
        margin: 0 auto;
        margin-top: 18px;
      }
    }
    .partner {
      ul {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0 30px;
      }
      li {
        width: calc((100% - 36px) / 3);
        margin: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #ededed;
        background: #ffffff;
        padding: 4px 20px;
        height: 90px;
        img {
          // width: 182px;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .doc {
    .flex(@direction: column);
    padding: 50px 0;
    background-image: url("~@/assets/images/home/doc_img.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: #fff;
    .title {
      text-align: center;
      h3 {
        font-size: 34px;
        font-weight: 600;
        margin-bottom: 16px;
      }
      h5 {
        font-size: 20px;
        opacity: 0.6;
        text-transform: uppercase;
      }
    }
    .detail {
      width: 80%;
      margin: 40px 0;
      line-height: 48px;
      font-size: 24px;
      text-align: center;
    }
    .more {
      .radius_button_border;
      color: #fff;
      border-color: #fff;
    }
  }
}
</style>
