const getters = {
  userInfo: state => state.user.userInfo,
  phone: state => state.user.userInfo.phone,
  token: state => state.user.token,
  userType: state => state.user.userType,
  navs: state => state.nav.navs,
  headerNavs: state => state.nav.headerNavs,
  contentIndex: state => state.content.contentIndex,
  contentAbout: state => state.content.contentAbout,
  contentJob: state => state.content.contentJob,
  contentEnglish: state => state.content.contentEnglish,
  contentContact: state => state.content.contentContact,
  contentDoc: state => state.content.contentDoc
}

export default getters
