import Vue from 'vue'
import moment from 'moment'
import 'moment/locale/zh-cn'
import { statusMap, statusCodeMap, typeMap } from './variable'
moment.locale('zh-cn')

Vue.filter('statusFilter', function (value) {
  return statusMap[value]
})

Vue.filter('statusCodeFilter', function (value) {
  return statusCodeMap[value]
})

Vue.filter('typeFilter', function (value) {
  return typeMap[value]
})
Vue.filter('changeLine', function (value) {
  return value.replace(/\r?\n/g, '<br />')
})

Vue.filter('NumberFormat', function (value) {
  if (!value) {
    return '0'
  }
  const intPartFormat = value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') // 将整数部分逢三一断
  return intPartFormat
})

Vue.filter('moment', function (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return !dataStr ? '' : moment(dataStr).format(pattern)
})
