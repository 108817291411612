import Vue from 'vue'
import 'ant-design-vue/dist/antd.css'
import { Alert, Icon, Input, Radio, Button, Spin, notification, Modal, Steps, Checkbox, Pagination, Empty, Upload, Table, Form, Select, FormModel, Breadcrumb, Menu, Anchor, DatePicker, Row, Col, Carousel } from 'ant-design-vue'
const antdObj = { Alert, Icon, Input, Radio, Button, Spin, notification, Modal, Steps, Checkbox, Pagination, Empty, Upload, Table, Form, Select, FormModel, Breadcrumb, Menu, Anchor, DatePicker, Row, Col, Carousel }

Object.entries(antdObj).forEach((item) => {
  const value = item[1]
  Vue.use(value)
})

Vue.prototype.$notification = notification
Vue.prototype.$modal = Modal
