<template>
  <keep-alive exclude="newsDetail,GPUList,user">
    <router-view></router-view>
  </keep-alive>
</template>

<script>
export default {
  name: 'keepAliveLayout'
}
</script>
