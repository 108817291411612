import Vue from 'vue'
import { Button, Toast, Loading, Dialog, Notify, Sticky, Swipe, SwipeItem, Collapse, CollapseItem, Popup, Empty, Skeleton, List, PullRefresh, Pagination, Form, Field, Icon, Step, Steps, Checkbox, Area, Picker } from 'vant'

const vantObj = { Button, Toast, Loading, Dialog, Notify, Sticky, Swipe, SwipeItem, Collapse, CollapseItem, Popup, Empty, Skeleton, List, PullRefresh, Pagination, Form, Field, Icon, Step, Steps, Checkbox, Area, Picker }

Object.entries(vantObj).forEach((item) => {
  const value = item[1]
  Vue.use(value)
})

Toast.setDefaultOptions({ duration: 1500, forbidClick: true })
