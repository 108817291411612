import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enUS from './lang/en-US.js'
import zhCN from './lang/zh-CN.js'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'zh-CN',
  messages: {
    'en-GB': enUS,
    // @ts-ignore
    'zh-CN': zhCN // 可以追加其他语言
  }
})

export default i18n
