import { routes } from '@/router/config'

const product = [
  {
    catalog: 'OrionX',
    cases: [
      {
        name: '视频中心',
        url: '/product/index'
      },
      {
        name: '用户指南',
        url: '/development/index'
      },
      {
        name: '申请试用',
        url: '/attention?isTry=true'
      }
    ],
    expand: false
  },
  {
    catalog: '趋动云',
    showNewTag: true,
    cases: [
      {
        name: '业界首朵“AI算力池化云”',
        url: 'https://www.virtaicloud.com/',
        isExternalLink: true
      }
    ],
    expand: false
  },
  {
    catalog: 'Gemini',
    showNewTag: false,
    cases: [
      {
        name: 'Gemini AI训练平台',
        url: '/product/gemini',
        isExternalLink: true
      }
    ],
    expand: false
  }
]

const partner = [
  {
    catalog: '渠道合作',
    cases: [
      {
        name: '合作伙伴门户',
        url: 'https://partner.virtaitech.com/',
        isExternalLink: true
      },
      {
        name: '考试证书查询',
        url: 'https://partner.virtaitech.com/certificate',
        isExternalLink: true
      }
    ],
    expand: false
  }
]

const company = [
  {
    catalog: '公司简介',
    cases: [
      {
        name: '关于趋动',
        url: '/company/about'
      },
      {
        name: '联系我们',
        url: '/contact/index'
      },
      {
        name: '诚聘英才',
        url: 'https://qudong.zhiye.com/home',
        isExternalLink: true
      },
      {
        name: '商务意向',
        url: '/attention'
      }
    ],
    expand: false
  }
]

const news = [
  {
    catalog: '博客与新闻',
    cases: [
      {
        name: '技术博客',
        url: '/company/techPages/list'
      },
      {
        name: '新闻资讯',
        url: '/company/news/list'
      }
    ],
    expand: false
  }
]

const headerNavs = [
  {
    title: '首页',
    path: '/',
    meta: {
      showOnNav: true
    }
  },
  {
    title: '产品',
    path: '/product',
    meta: {
      showOnNav: true
    },
    children: product
  },
  {
    children: true,
    path: '/solution',
    title: '解决方案',
    meta: {
      showOnNav: true,
      dynamicChildren: true,
      headerWhite: true
    }
  },
  {
    title: '公司',
    path: '/commpany',
    meta: {
      showOnNav: true
    },
    children: company
  },
  {
    title: '博客与新闻',
    path: '/news',
    meta: {
      showOnNav: true
    },
    children: news
  },
  {
    title: '合作伙伴',
    path: '/partner',
    meta: {
      showOnNav: true
    },
    children: partner
  }
]

export default {
  state: {
    navs: [],
    headerNavs
  },
  mutations: {
    setNavs (state, payload) {
      state.navs = payload
    }
  },
  actions: {
    createNavs ({ commit }) {
      const navArr = []
      routes[0].children.forEach(item => {
        if (item.meta && item.meta.showOnNav) {
          const tempObj = {
            title: item.meta.title,
            link: item.meta.link ? item.meta.link : '',
            path: item.path,
            meta: item.meta
          }
          if (item.children && item.children.length > 0) {
            tempObj.children = item.children.filter(current => {
              return current.meta.showOnNav
            })
          }
          if (item.meta.dynamicChildren) {
            tempObj.children = true
          }
          navArr.push(tempObj)
        }
      })
      commit('setNavs', navArr)
    }
  }
}
