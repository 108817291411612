import api from '@/store/api'
import svgPath from '@/assets/svgPath'
export default {
  data () {
    return {
      api,
      svgPath
    }
  },
  mounted () {
  }
}
