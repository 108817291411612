import HttpUtil from '@/utils/HttpUtil'
import { AppKey } from '@/utils/variable'
import Vue from 'vue'
import { Toast } from 'vant'

export const getCMSUrl = function () {
  let apiUrl = ''
  if (process.env.NODE_ENV === 'production') {
    apiUrl = appEnv.config.cmsApiAddress
  } else {
    apiUrl = '/api'
  }
  return apiUrl
}

export const cmsUrl = getCMSUrl()

const cmsHttpUtil = new HttpUtil({
  baseURL: cmsUrl,
  headers: {
    AppKey
  },
  timeout: 1000 * 30
})

cmsHttpUtil.instance.interceptors.request.use(config => {
  const token = Vue.ls.get('token')
  if (token) {
    config.headers.Authorization = `Bearer ${token}` // 让每个请求携带自定义 token 请根据实际情况自行修改
  }
  return config
}, () => {})

// response interceptor
cmsHttpUtil.instance.interceptors.response.use((response) => {
  const { data, status } = response
  if (status !== 200) {
    const msg = '查询CMS信息失败'
    Toast.fail(msg)
    return Promise.reject(status)
  } else {
    return data.data
  }
}, (error) => {
  // 暂行办法-避免build打包时将错误弹窗打包入页面
  if (typeof error === 'object' && error.message !== 'Network Error') {
    Toast.fail('网络错误，请稍后重试')
  }
  return Promise.reject(error)
})

export default cmsHttpUtil
