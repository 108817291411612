import Vue from 'vue'
import api from '../api'
import { TOKEN, USER_INFO, USER_TYPE } from '@/utils/variable'
import { Toast } from 'vant'
export default {
  state: {
    token: '',
    userInfo: {},
    userType: 0
  },
  mutations: {
    setToken (state, payload) {
      state.token = payload
    },
    setUserInfo (state, payload) {
      state.userInfo = payload
    },
    setUserType (state, payload) {
      state.userType = payload
    }
  },
  actions: {
    async signin ({ dispatch, commit, state }, params) {
      return new Promise((resolve, reject) => {
        api.signin(params).then(async data => {
          const { accessToken, expiredIn, userType } = data
          const expire = expiredIn * 60 * 60 * 1000

          Vue.ls.set(TOKEN, accessToken, expire)
          Vue.ls.set(USER_TYPE, userType, expire)
          commit('setToken', accessToken)
          commit('setUserType', userType)

          await dispatch('fetchUserInfo', expire)
          resolve(true)
        }).catch(error => {
          reject(error)
        })
      })
    },
    async signup ({ dispatch, commit, state }, params) {
      return new Promise((resolve, reject) => {
        api.signup(params).then(async data => {
          const { accessToken, expiredIn, userType } = data
          const expire = expiredIn * 60 * 60 * 1000

          Vue.ls.set(TOKEN, accessToken, expire)
          Vue.ls.set(USER_TYPE, userType, expire)
          commit('setToken', accessToken)
          commit('setUserType', userType)

          await dispatch('fetchUserInfo', expire)
          resolve(true)
        }).catch(error => {
          reject(error)
        })
      })
    },
    async signout ({ commit }) {
      return new Promise((resolve, reject) => {
        Vue.ls.remove(TOKEN)
        Vue.ls.remove(USER_INFO)
        Vue.ls.remove(USER_TYPE)
        commit('setToken', '')
        commit('setUserInfo', '')
        commit('setUserType', '')
        Toast('您已成功退出')
        resolve()
      })
    },
    async fetchUserInfo ({ dispatch, commit, state }, expire) {
      return new Promise((resolve, reject) => {
        api.fetchUserInfo().then(data => {
          Vue.ls.set(USER_INFO, data, expire)
          commit('setUserInfo', data)
          resolve(true)
        }).catch(error => {
          dispatch('signout')
          reject(error)
        })
      })
    }
  }
}
