<template>
  <div class="index_layout">
    <c-header :lang="language" :isWhiteProp="headerWhite" />
    <div class="index_container">
      <router-view></router-view>
    </div>
    <c-footer v-show="showFooter" v-if="language !== 'English'" />
    <c-right :lang="language" />
    <div v-if="showTip" class="fix-tip" :class="{ 'tip-hidden': isScrolling }">
      <div class="attention" @click="toAttention">
        <img src="@/assets/images/icon-message.png" />
        <div class="text">{{ $t('layout.default.productInquiry') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import header from '~/components/header'
import footer from '~/components/footer'
import copyRight from '~/components/copyRight'
export default {
  name: 'indexLayout',
  components: {
    cHeader: header,
    cFooter: footer,
    cRight: copyRight
  },
  methods: {
    toAttention () {
      const path = this.language === 'English' ? '/en/attention' : '/attention'
      if (this.$route.path.includes('/solution/')) {
        this.$router.push({ path, query: { bz: 'solution' } })
      } else {
        this.$router.push({ path })
      }
    }
  },
  computed: {
    language () {
      return this.$route.fullPath.startsWith('/en') ? 'English' : '简体中文'
    },
    showFooter () {
      const list = ['/attention', '/case/detail']
      return !list.some(i => i === this.$route.path)
    },
    headerWhite () {
      const list = ['/attention', '/case/detail']
      return list.some(i => i === this.$route.path)
    },
    showTip () {
      return this.$route.path !== '/attention'
    }
  },
  mounted () {
    // 利用防抖原理，实现滚动中隐藏商务意向
    let to = setTimeout(() => {
      this.isScrolling = false
    }, 600)
    window.addEventListener('scroll', () => {
      this.isScrolling = true
      if (to) {
        clearTimeout(to)
        to = setTimeout(() => {
          this.isScrolling = false
        }, 600)
      }
    })
  },
  data () {
    return {
      isScrolling: false
    }
  }
}
</script>
<style lang="less" scoped>
.index_layout {
  .fix-tip {
    position: fixed;
    right: 16px;
    bottom: 212px;
    z-index: 5;
    transition: 0.3s;
    &.tip-hidden {
      right: -66px;
      opacity: 0.5;
    }
    .attention {
      width: 96px;
      height: 168px;
      background: rgba(0, 115, 230, 0.85);
      border-radius: 8px;
      border: 1px solid rgba(255, 255, 255, 0.65);
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        margin-top: 20px;
        height: 48px;
        width: 48px;
      }
      .text {
        margin-top: 8px;
        font-size: 22px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 36px;
        padding: 0 15px;
        text-align: center;
      }
    }
  }
}
</style>
