export default {
  header: {
    title: '首页',
    lang: '简体中文'
  },
  coopCase: {
    wholeCase: '完整案例'
  },
  news: {
    component: {
      details: '查看详情',
      more: '更多资讯'
    },
    detail: {
      title: '资讯详情',
      prev: '上一篇：',
      next: '下一篇：'
    }
  },
  caseDetail: {
    introduction: '案例简介'
  },
  layout: {
    default: {
      productInquiry: '商务意向'
    }
  },
  attention: {
    title: '商务意向',
    secTitle: '为了更好的支持您的业务，请留下以下信息',
    form: {
      attention: {
        label: '业务意向',
        radio1: '产品',
        radio2: '行业解决方案'
      },
      appellation: {
        label: '客户称呼',
        placeholder: '如何称呼您'
      },
      companyName: {
        label: '所属公司',
        placeholder: '请输入您供职的公司'
      },
      position: {
        label: '职位名称',
        placeholder: '请输入您的职位'
      },
      department: {
        label: '所属部门',
        placeholder: '请输入您所在部门'
      },
      contact: {
        label: '联系方式',
        placeholder1: '请输入单位电话或个人手机号',
        placeholder2: '请输入您的电子邮箱'
      },
      demo: {
        label: 'demo演示环境',
        radio1: '申请',
        radio2: '不申请'
      },
      funcDescription: {
        label: '功能描述',
        placeholder: '请描述您希望了解哪些功能'
      },
      target: {
        label: '演示目标',
        placeholder: '请输入演示目标'
      },
      projectRequire: {
        label: '项目需求',
        placeholder: '如有具体项目需求，请填写',
        limit: '已输入'
      },
      needDoc: {
        label: '解决方案资料',
        radio1: '申请',
        radio2: '不申请'
      },
      docList: {
        label: '所需资料',
        options: {
          label1: '解决方案',
          label2: '单页',
          label3: '白皮书',
          label4: '其他'
        }
      },
      receiveEmail: {
        label: '接收邮箱',
        placeholder: '请输入用于接收资料的电子邮箱'
      },
      message: {
        label: '意向留言',
        placeholder: '请输入您关于试用、购买产品或其他商业意愿的具体内容',
        limit: '已输入'
      },
      rule: {
        appellation: '客户称呼为必填项',
        companyName: '所属公司为必填项',
        contact: {
          error1: '联系方式的电话号码与电子邮箱至少填写一项',
          error2: '请输入正确的电话号码',
          error3: '请输入正确的邮箱',
          error4: '电话号码为必填项'
        },
        funcDescription: '功能描述为必填项',
        target: '演示目标为必填项',
        docList: '所需资料为必填项',
        receiveEmail: {
          error1: '请输入正确的邮箱',
          error2: '接收邮箱为必填项'
        },
        message: '意向留言为必填项'
      }
    },
    button: {
      submit: '提交',
      tip1: '提交意向后，工作人员会尽快联系您',
      tip2: '如果您现在就需要咨询，可拨打 010-62560919 与我们联系'
    },
    modal: {
      success: '感谢！您的意向信息已成功提交至产品管理人员。核实信息后我们会尽快联系您。',
      warn: '业务意向错误',
      error: '请稍后重试'
    }
  }
}
